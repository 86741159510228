import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    useDisclosure,
    useToast,
  } from '@chakra-ui/react'
import { useRef } from 'react'
import telegram from '../MainSite/Telegram';
import { TbStatusChange } from "react-icons/tb";
import { FaDatabase } from 'react-icons/fa';
import { IoMdArrowBack } from 'react-icons/io';

export default function ChangeTcLeaveButton(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()
    const toast =useToast();
        const notify = (newRes) => toast({
            title: newRes.title,
            description: newRes.description,      
            status: newRes.status,
            duration: 9000,
            isClosable: true,
            position:'top-right'
        })
    const handleSubmit = async event => {
      var message="";
      onClose();
      const a = await (await fetch(process.env.REACT_APP_API+`/db_change_student_invoice_tc_leave_status?tc_leave=${props.tcLeave}&inv_no=${props.invNo}&date_of_purchase=${props.invDate}`)).json();
      notify({title: 'Action Successful', description: a.message, status: 'success'});
      props.setTcLeave(!props.tcLeave)
      (!props.tcLeave) ? (message = `Status of Invoice# ${props.invNo} Changed from Marked NO for TC/Leave to Marked YES for TC/Leave.` ): (message= `Status of Invoice# ${props.invNo} Changed from Marked YES for TC/Leave to Marked NO for TC/Leave.`)
      telegram(message)
    }

    return (
      <>
        <Button onClick={onOpen} colorScheme='purple' variant='outline' leftIcon={<TbStatusChange/>}>Change TC/Leave Status</Button>
        <AlertDialog
          motionPreset='slideInBottom'
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
  
          <AlertDialogContent>
            <AlertDialogHeader>Change TC/Leave Status?</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              Are you sure you want to make the change to this invoice. Click on Confirm to proceed.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} colorScheme='pink' leftIcon={<IoMdArrowBack/>}>
                Go Back
              </Button>
              <Button colorScheme='whatsapp' ml={3} onClick={handleSubmit} leftIcon={<FaDatabase/>}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    )
  }