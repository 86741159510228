import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
    useDisclosure,
    useToast,
  } from '@chakra-ui/react'
import { useRef } from 'react'
import telegram from '../MainSite/Telegram';
import { MdDelete } from 'react-icons/md';
import { IoMdArrowBack } from 'react-icons/io';
import { FaDatabase } from 'react-icons/fa';

export default function DeleteInvoiceButton(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const toast =useToast();
        const notify = (newRes) => toast({
            title: newRes.title,
            description: newRes.description,      
            status: newRes.status,
            duration: 9000,
            isClosable: true,
            position:'top-right'
        })
    const handleSubmit = async event => {
      onClose();
      const a = await (await fetch(process.env.REACT_APP_API+`/db_delete_student_invoice?inv_no=${props.invNo}&date_of_purchase=${props.invDate}`)).json();
      notify({title: 'Action Successful', description: a.message, status: 'success'});
      const message = `Invoice# ${props.invNo} deleted from Database. If done by mistake then kindly re-create the same invoice.`
      telegram(message);
      props.handleClear();
    }

    return (
      <>
        <Button onClick={onOpen} colorScheme='red' variant='outline' leftIcon={<MdDelete/>}>Delete Invoice</Button>
        <AlertDialog
          motionPreset='slideInBottom'
          leastDestructiveRef={cancelRef}
          onClose={onClose}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
  
          <AlertDialogContent>
            <AlertDialogHeader>Delete Invoice?</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              Are you sure you want to delete this invoice. This action cannot be reversed. Click on Confirm to proceed.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} colorScheme='pink' leftIcon={<IoMdArrowBack/>}>
                Go Back
              </Button>
              <Button colorScheme='whatsapp' ml={3} onClick={handleSubmit} leftIcon={<FaDatabase/>}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    )
  }