import { Center, Radio, RadioGroup, VStack, Heading, HStack, FormControl, FormLabel, Skeleton, Td, Th, Table, Tbody, Thead, Tr, Input, Button, AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure,
    Icon,
    CircularProgress, } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { MdInventory } from "react-icons/md";
import { CustomToast } from "../MainSite/Toast";
import { useNavigate } from "react-router-dom";
import telegram from "../MainSite/Telegram";
import { CgDanger } from "react-icons/cg";
import { FcMultipleInputs } from "react-icons/fc";

export default function Inventory(){

    const [type, setType] = useState(''); // capture the data from radio buttons
    const [inventoryData, setInventoryData] = useState([]);
    var [finalInventoryData, setFinalInventoryData] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const [processing, setProcessing] = useState(false);
    const {addToast} = CustomToast();
    const [loading,setLoading] =useState(window.localStorage.getItem('inventorySkeletonLoading') === null ?
        window.localStorage.setItem('inventorySkeletonLoading', true) : window.localStorage.getItem('inventorySkeletonLoading')
      );
    const [confirmDisable, setConfirmDisable] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          const response = await fetch(process.env.REACT_APP_API+`/db_product_search?school_id=${window.localStorage.getItem('school_id')}`);
          var newData = await response.json();
          setInventoryData(JSON.parse(newData));
          setLoading(false);
          setFinalInventoryData({});
        };
        fetchData();
      },[]);
    const navigate = useNavigate();
      const onChangeInput = (e, index) => {
        const { name, value } = e.target
        inventoryData[index][name]=value;
    }
    
    const stockManipulation = () => {
        setProcessing(true)
        setConfirmDisable(true)
        //slicing the entire object to keep only the values with a input
        for (let x in inventoryData){
            if (Object.keys(inventoryData[x]).includes('entry'))
                finalInventoryData[x]=inventoryData[x]
        }
        const persistData = async () => {
            const response= await fetch(process.env.REACT_APP_API+'/db_stock_input', {
            
            // Adding method type
            method: "POST",
            
            // Adding body or contents to send
            body: JSON.stringify({
                type:type,
                school_id:window.localStorage.getItem('school_id'),
                products: finalInventoryData,
            }),
            
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            
    // Converting to JSON
    const newData = await response.json();
    if (newData['response'] === 500){
        addToast({title: 'Error', description: 'Wrong Input Format. Correct the input and try again', status: 'error'})
        setConfirmDisable(false)
        let message = `Inventory could not be updated by user ${window.localStorage.getItem('user_email')} due to wrong input formats.`
        telegram(message)
    }
        if (newData['response'] === 200){
        addToast({title: 'Success', description: 'Stock Entered Successfully', status: 'success'})
        let message = `Inventory updated by user ${window.localStorage.getItem('user_email')} successfully.`
        telegram(message)
        navigate('/home')
    }
    if (newData['response'] === 100){
        addToast({title: 'Partial Success! Some Items were Ignored', description: 'Stock Entered Successfully but some items were ignored as they are not present in the current Inventory', status: 'warning'})
        setProcessing(false)
        let message = `Inventory updated by user ${window.localStorage.getItem('user_email')}. However some items were ignored as they were not present in the inventory.`
        telegram(message)
        navigate('/home')
    }
    }   
    persistData();
    }

    return(
        <Center>
            <VStack spacing={5} borderWidth={1} borderRadius={8} boxShadow="lg" p={5}>
                <Heading as={'h3'} size={'lg'} pb={5}><Icon as={MdInventory} boxSize={6}/>&nbsp; Inventory Management System</Heading>
                <Heading as={'h5'} size={'sm'} pb={5} color="grey"><Icon as={FcMultipleInputs} boxSize={4}/>&nbsp; Input format is Size1:Qty1,Size2:Qty2, ... Example Input is 30:200,32:100,34:500 where 30,32,34 are the sizes and 200,100,500 are the respective quantities</Heading>
                <Heading as={'h5'} size={'xs'} pb={5} color="grey" _hover={{color:'red'}}><Icon as={CgDanger} boxSize={3}/>&nbsp; Use the Correct Existing Stock option to correct any stock that has been wrongly entered</Heading>
                <FormControl isRequired>
                    <Center>
                        <RadioGroup>  
                            <HStack>
                                <FormLabel fontSize={'sm'}>Select Operation</FormLabel>  
                                <Radio value={'1'} name="type" onChange={(e)=>setType(e.target.value)}>Enter New Stock</Radio>
                                <Radio value={'2'} name="type" onChange={(e)=>setType(e.target.value)}>Correct Existing Stock</Radio> 
                            </HStack>
                        </RadioGroup>
                    </Center> 
                </FormControl>
                <Skeleton isLoaded={!loading}>
                    <Table>
                    <Thead>
                        <Tr>
                        <Th>Sr.</Th>
                        <Th textAlign={'center'}>Item Name</Th>
                        <Th textAlign={'center'}>Enter Stock</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {inventoryData.map((value,index)=> {
                        return(
                        <Tr key={index}>
                        <Th>{index+1}</Th>
                        <Th>{value['product_name']}</Th>
                        <Td>
                            <Input w={'50em'} name="entry" onChange={(e)=> onChangeInput(e, index)}/>
                        </Td>
                        </Tr>)})}
                    </Tbody>
                </Table>
                </Skeleton>
                <Center>
                    <Button onClick={onOpen} isDisabled={type === '' ? true : false} variant={'solid'} colorScheme="telegram" rightIcon={<IoArrowForward/>}>Confirm</Button>
                    <AlertDialog
                        motionPreset='slideInBottom'
                        leastDestructiveRef={cancelRef}
                        onClose={onClose}
                        isOpen={isOpen}
                        isCentered
                    >
                        <AlertDialogOverlay />

                        <AlertDialogContent>
                        <AlertDialogHeader>You are about to make changes in the Inventory?</AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                            {type === '1'? 'This action will add new stock to the existing stock.' : type === '2' ? 'This action will overwrite the existing values with the new ones.' : null}
                            Click Proceed to make changes in database.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose} colorScheme="red" leftIcon={<IoArrowBack/>}>
                            Go Back
                            </Button>
                            <Button isDisabled={confirmDisable} colorScheme='whatsapp' ml={3} onClick={stockManipulation} leftIcon={<MdInventory/>}>
                            { processing ? <CircularProgress isIndeterminate size="24px" color="teal"/> : 'Proceed' }
                            </Button>
                        </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </Center>
            </VStack> 
            </Center>         
            
        
    )
}