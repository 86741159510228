import { Menu, MenuButton, MenuList, MenuItem, IconButton } from "@chakra-ui/react";
import { HamburgerIcon } from '@chakra-ui/icons';
import { FaHome   } from "react-icons/fa";
import { MdInventory, MdLocalGroceryStore, MdOutlinePivotTableChart   } from "react-icons/md";
import { AiFillDashboard } from "react-icons/ai";
import { TbFileInvoice } from "react-icons/tb";
import { FaMoneyBillTrendUp } from "react-icons/fa6";

import { useNavigate } from "react-router-dom";

export default function NavMenu(){
  const navigate = useNavigate();

  return(
        
                                  
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label='Options'
                icon={<HamburgerIcon />}
                size='lg'
              />
              <MenuList>
                <MenuItem icon={<FaHome />}  onClick={()=>{navigate('/home')}}>
                  Home
                </MenuItem>
                <MenuItem icon={<TbFileInvoice />}  onClick={()=>{navigate('/home/invoice/student/createInvoice')}}>
                  Student Invoice
                </MenuItem>
                <MenuItem icon={<FaMoneyBillTrendUp/>} onClick={()=>{navigate('/home/invoice/principal/createInvoice')}}>
                  Bill To School Principal
                </MenuItem>
                <MenuItem icon={<MdOutlinePivotTableChart/>} onClick={()=> navigate('/home/coverPages')}>
                  House Cover Pages
                </MenuItem>
                <MenuItem icon={<MdInventory />} onClick={()=> navigate('/home/inventory')}>
                  Inventory
                </MenuItem>
                <MenuItem icon={<AiFillDashboard/>} onClick={()=> navigate('/home/dashboard')}>
                  Dashboard 
                </MenuItem>
                {localStorage.getItem('school_id') === '2' ? (
                  <MenuItem icon={<MdLocalGroceryStore/>} onClick={()=> navigate('/home/raashanShop')}>
                  Raashan Bill
                </MenuItem>
                ):(null)}
              </MenuList>
            </Menu>
            )
    
}