import { Box, HStack, VStack, Center, Heading, Divider, UnorderedList, ListItem, Badge, Text, Icon } from "@chakra-ui/react";
import { SlideData } from "./SlideData";
import ImageSlider from "./ImageSlider";
import { MdEmail } from "react-icons/md";
import { FaMobileAlt,   } from "react-icons/fa";

export default function Content(){
    return(
        <Box w="100%" p={4}>
                <ImageSlider slides={SlideData} />        
            <HStack spacing={5} justifyContent={'center'} mb={20}>
                <Box borderRadius={'5%'} border={'2px'} borderColor={'grey'} w={'20em'} h={'20em'}>
                  <VStack spacing={2} m={2}>  
                  <Center>
                    <Heading>About Us</Heading>
                  </Center>
                  <Divider />
                  <Text w={'15em'}>
                    We are Government of India Contractors having over 
                    5 years of experience in delivering excellent tender supply services . 
                    We have partnered with several vendors to provide quality products at reasonalble price. 
                    Contact us for tender fullfillment & management services related queries.
                    </Text>
                  </VStack>
                </Box>
                
                <Box borderRadius={'5%'} border={'2px'} borderColor={'grey'} w={'20em'} h={'20em'}>
                  <VStack spacing={2} m={2}>
                  <Center>
                    <Heading>Our Services</Heading>
                  </Center>
                  <Divider/>
                  <Center>
                  <UnorderedList >
                    <ListItem>Clothing Supply</ListItem>
                    <ListItem>Mess Food Items Supply</ListItem>
                    <ListItem>Infrastructure</ListItem>
                    <HStack>
                    <ListItem>Clothing Manufacturer</ListItem>
                    <Badge colorScheme='purple'>New</Badge>
                    </HStack>
                </UnorderedList>
                </Center>
                </VStack>
                </Box>

                <Box borderRadius={'5%'} border={'2px'} borderColor={'grey'} w={'20em'} h={'20em'}>
                  <VStack spacing={2} m={2}>
                  <Center>
                    <Heading>Contact Us</Heading>
                  </Center>
                  <Divider/>
                  <Center>
                  <VStack>
                    <HStack>
                      <Icon aria-label='Invoice' as={MdEmail } m={2}  /><Text>care@prikaway.com</Text>
                    </HStack>
                    <HStack>
                      <Icon aria-label='Invoice' as={FaMobileAlt } m={2}  /><Text>+91-8002003353</Text>
                    </HStack>
                  </VStack>
                  </Center>
                  </VStack>
                </Box>
            </HStack>
            
            </Box>
    )
}