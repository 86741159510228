import { Icon, Button, Center, FormLabel, HStack, Link, VStack, Heading, Tag, TagLeftIcon, TagLabel, Text, PinInput, PinInputField } from "@chakra-ui/react";
import { useState } from "react";
import telegram from "../MainSite/Telegram";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { CustomToast } from "../MainSite/Toast";
import { AiFillDashboard } from "react-icons/ai";
export default function Dashboard(){
    const {addToast} = CustomToast();
    const [stage, setStage] = useState(false);
    const [pin, setPin] = useState({
        a:"",
        b:"",
        c:"",
        d:""
    })
    const [flag, setFlag] = useState(false);
    const [view, setView] = useState(false);
    const [otp, setOTP] = useState('');
    const [disabled, setDisabled] = useState(false);
    
    function OTP(){
        let num = Math.round(Math.random()*10000)
        telegram(`OTP Generated to view the Dashboard is ${num}`)
        setStage(true)
        setOTP(num)
        setFlag(false)
    }

    const goBack = () => {
        setStage(false)
        setView(false)
        setDisabled(false)
    }

    const check = () => {
        
        let pow = 3;
        let num = 0;
        for (let x in pin){
            num = num + (pin[x]*Math.pow(10,pow))
            pow--
        }
        if (otp === num){
            setView(true)
            setFlag(true)  
            setDisabled(true)
            const message = `User ${window.localStorage.getItem('user_email')} viewed the Dashboard.`
            telegram(message)

        } 
        else
            addToast({title: 'Authorization Failed', description: 'Wrong OTP Entered', status: 'error'})
         
    }

    return(
        <Center m={20}>
            <VStack p={10} borderWidth={1} borderRadius={8} boxShadow="lg" mb={'20em'}>
            {stage? 
                <VStack spacing={5}>
                    <Center>
                        <FormLabel>Enter OTP</FormLabel>
                        
                    </Center>

                    <HStack>
                        <PinInput otp>
                            <PinInputField onChange={(e) => setPin({...pin, a:e.target.value})}/>
                            <PinInputField onChange={(e) => setPin({...pin, b:e.target.value})}/>
                            <PinInputField onChange={(e) => setPin({...pin, c:e.target.value})}/>
                            <PinInputField onChange={(e) => setPin({...pin, d:e.target.value})}/>
                        </PinInput>
                    </HStack>

                    <HStack spacing={5}>
                        <Button onClick={check} variant={'solid'} colorScheme="whatsapp" isDisabled={disabled}>Submit</Button>
                        <Button onClick={goBack} variant={'solid'} colorScheme="pink">Go Back</Button>
                    </HStack>
                    <Text>Click Go Back to resend OTP</Text>
                </VStack>
                    :<VStack spacing={10}>
                        <Heading as={'h3'} size={'lg'}><Icon as={AiFillDashboard} boxSize={6}/>&nbsp; Dashboard</Heading>
                        <Heading as='h5' size='sm'>To view the Dashboard, Click Generate OTP</Heading>
                        <Button onClick={OTP} variant={'solid'} colorScheme="telegram">Generate OTP</Button>
                    </VStack>}
            {view?
                flag?(
                    <Tag  variant='outline' colorScheme='orange'>
                        <TagLeftIcon boxSize='12px' as={ExternalLinkIcon} />
                        <TagLabel>                        
                            <Link href="https://lookerstudio.google.com/reporting/7c229a32-5b87-4f8f-bf17-f36016408de5/page/s4OYD" isExternal variant={'ghost'}>Click Here to view Dashboard</Link>
                        </TagLabel>
                    </Tag>    
                    
                ):(
                    <Heading>Wrong OTP</Heading>
                )
            :null}
            </VStack>
        </Center>
    )
}