import { ButtonGroup, IconButton, HStack, Text, Spacer, Flex, Center, Box, Image } from '@chakra-ui/react'
import { FaLinkedin, FaFacebook, FaWhatsapp  } from 'react-icons/fa'

export default function Footer () {
  return(
  <Flex as="footer" role="contentinfo"  bg='grey' w={'100%'} position={'relative'} bottom={0} mt={10}>
    <Box>
      <Center ml={2} mt={1.5}>
        <Text fontSize="md" color="fg.subtle">
          &copy; {new Date().getFullYear()} Prika Way Pvt. Ltd. All rights reserved.
        </Text>
      </Center>
    </Box>  
    <Spacer/>
    <Box>
      <HStack>
      <Text fontSize="md" color="fg.subtle">Product of </Text>
      <Image src="https://i.postimg.cc/Gh5QMp8c/india.png" boxSize={'2.5em'}/>
      </HStack>
    </Box>
    <Spacer/>
    <Box>
      <ButtonGroup variant="tertiary">
        <IconButton as="a" href="https://web.whatsapp.com/" aria-label="Whatsapp" icon={<FaWhatsapp/>} />
        <IconButton as="a" href="https://www.facebook.com" aria-label="Facebook" icon={<FaFacebook />} />
        <IconButton as="a" href="https://www.linkedin.com/company/prika-way-private-limited/mycompany/" aria-label="LinkedIn" icon={<FaLinkedin />} />
      </ButtonGroup>
    </Box>
  </Flex>
)
}