import {Center} from '@chakra-ui/react';
import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import PrincipalBillPDFTableHeader from './PrincipalBillPDFTableHeader';
import PrincipalInvoiceTableProducts from './PrincipalBillPDFTableProducts';
import telegram from '../MainSite/Telegram';
import { CustomToast } from '../MainSite/Toast';
// Create styles
const borderColor = '##D5DBDB'
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#E4E4E4',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily:'Courier',
    margin:'1px',
  },
  section: {
    margin: 'auto',
    fontSize: 12,
  },
  footer: {
    margin: 'auto',
	  fontSize: 10
  },
  totalsection:{
    borderWidth: 1,
    borderStyle: 'dashed',
  },
  container1: {
    flexDirection: 'row',
    borderWidth: 1,
    borderStyle: 'dashed',
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
    height: 24,
},
  date: {
    width: '50%',
    borderRightWidth: 1,
    borderColor:borderColor,
    textAlign: 'left',
  },
  invNo: {
    width: '50%',
  }, 
});


// Create Document Component
function  MyDocument (data) {
  const {addToast} = CustomToast();
  
    let x =data.header 
    // Displaying the toast
    addToast({title: 'Success', description: 'Invoice Created Successfully', status: 'success'})
    //ping on telegram
    const message = `Principal Bill Generated
School Name : ${x['school_name']}
Invoice No : ${x['inv_no']}
Invoice Date : ${x['bill_date']}
Total Number of Items : ${Number(x['item_quantity']).toLocaleString('en-IN')}
Total Amount Payable : Rs. ${Number(x['total_price']).toLocaleString('en-IN')}
This bill is not saved in the database and is non editable. In case of any errors please generate a new bill.`
    telegram(message)

  return(
  <Document title={x['inv_no']}>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.section}>School Principal Invoice</Text>
		<Text style={styles.section}>Prika Way Private Limited</Text>
    <Text style={styles.section}>Unit: Tuck Shop {x['school_name']}</Text>
    <Text style={styles.section}>Customer Care: care@prikaway.com, +91-8002003353  GSTIN: 10AAKCP3572D1ZR</Text>
    <View style={styles.container1}>
        <Text style={styles.date}>Date: {x['bill_date']}</Text>
        <Text style={styles.invNo}>Invoice No: {x['inv_no']}</Text>
    </View>
    <Text>&nbsp;</Text>
    <PrincipalBillPDFTableHeader/>
    <PrincipalInvoiceTableProducts products={data.products}/>
    <Text>&nbsp;</Text>
    <View style={styles.totalsection}>
    <Text>Total Number of Items: {x['item_quantity']}</Text>
    <Text>Total Amount Payable: {Number(x['total_price']).toLocaleString('en-IN')}</Text>
    <Text>Total Amount Payable(In Words): {x['word_amount']}</Text>
    </View>
    <Text>&nbsp;&nbsp;</Text>
		<Text style={styles.footer}>All prices are in INR and inclusive of GST</Text>
    <Text>&nbsp;&nbsp;</Text>
    <Text>&nbsp;&nbsp;</Text>
    <Text>&nbsp;&nbsp;</Text>
    <Text>&nbsp;&nbsp;</Text>
    <Text>&nbsp;&nbsp;</Text>
    <Text>&nbsp;&nbsp;</Text>
    <Text style={styles.footer}>Principal Signature&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vendor Signature</Text>
    
    </View>
    </Page>
  </Document>
);
  }

export default function PrinciaplBillPDF(props) {
  //const header = JSON.parse(props)
  //const ert= useContext(StudentInvoiceContext)
  const ter = props.PrincipalInvSearchData
  var headerData= ter['header']
  var productData = JSON.parse(ter['products'])
  
    return(    
    <Center>
    <PDFViewer height={'900em'} width={'1000em'}>
        <MyDocument header={headerData} products={productData}/>
      </PDFViewer>
    </Center>
    )
}