import{
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { useRef } from 'react';
import LoginForm from './LoginForm';

export default function LoginDrawer(props){
    const { isOpen, onOpen, onClose,  } = useDisclosure()
    const btnRef = useRef()

    return(
        <>
        <Button colorScheme='facebook' variant='solid' onClick={onOpen}>Log In</Button>
        <Drawer
              isOpen={isOpen}
              placement='right'
              onClose={onClose}
              finalFocusRef={btnRef}
              size='sm'
              borderBottomWidth='1px'
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>ENTERPRISE MANAGEMENT SYSTEM</DrawerHeader>

                <DrawerBody>
                  <LoginForm onClose={onClose} setSchoolData={props.setSchoolData}/>
                </DrawerBody>
              </DrawerContent>
            </Drawer>   
        </> 
    )
}