import InventoryView from '../Inventory/InventoryView';
import SearchBox from './SearchBox';

export default function HomePage(props){
    return(
            <>
            <SearchBox setStudentInvSearchData={props.setStudentInvSearchData} setStudentInvSearchCriteria={props.setStudentInvSearchCriteria}/>
            <InventoryView/>
            </>
        );
};