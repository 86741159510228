import React, {Fragment} from 'react';
import {Text, View } from '@react-pdf/renderer';
import {styles} from './RaashanPDFTableHeader';
  
function RaashanPDFTableProducts (props) {
    
    var counter=0
    const rows = props.products.map( item => 
        <View style={styles.productRows} key={item['item_name']}>
            <Text style={styles.sr}>{counter+=1}</Text>
            <Text style={styles.description}>{item['item_name']}</Text>
            <Text style={styles.rate}>{item['rate']}</Text>
            <Text style={styles.gst}>{item['gst_amount']}</Text>
            <Text style={styles.qty}>{Number(item['item_quantity']).toLocaleString('en-IN')}</Text>
            <Text style={styles.price}>{Number(item['total_price']).toLocaleString('en-IN')}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  export default RaashanPDFTableProducts;