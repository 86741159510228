import {Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Box
  } from '@chakra-ui/react';
export default function InvoiceInputAlert(props){
    let message = "";
    let title= "";
        if (props.alertType === 1){
            message = "One or more products or sizes are not available in the inventory. First input stock for them and then generate invoices";
            title = "Inventory Error";                                    
        } else if (props.alertType === 2){
            message = "You are trying to make an Invoice which already exists for the given Date and Roll No. Correct details and try again.";
            title = "Database Error!";
        } else if (props.alertType === 3){
            message = "Add some item(s) to continue.";
            title = "No Item(s) Selected!";
        }
            return(
                <Alert status='error'>
                    <AlertIcon />
                    <Box>
                        <AlertTitle>{title}</AlertTitle>
                        <AlertDescription>{message}</AlertDescription>
                    </Box>
                    </Alert>
                )
                
        }

    
