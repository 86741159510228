import {Center, } from '@chakra-ui/react';
import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import AllHouseCoverPDFTableHeader from './AllHouseCoverPDFTableHeader';
import AllHouseCoverPDFTableRows from './AllHouseCoverPDFTableRows';
import { CustomToast } from '../MainSite/Toast';
import telegram from '../MainSite/Telegram';
// Create styles
const borderColor = '##D5DBDB'
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#E4E4E4',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily:'Courier',
    margin:'1px',
  },
  section: {
    margin: 'auto',
    fontSize: 12,
  },
  footer: {
    margin: 'auto',
	  fontSize: 10
  },
  totalsection:{
    borderWidth: 1,
    borderStyle: 'dashed',
  },
  container1: {
    flexDirection: 'row',
    borderWidth: 1,
    borderStyle: 'dashed',
    alignItems: 'center',
    textAlign: 'center',
    flexGrow: 1,
    height: 24,
},
  date: {
    width: '50%',
    borderRightWidth: 1,
    borderColor:borderColor,
    textAlign: 'left',
  },
  invNo: {
    width: '50%',
  }, 
});


// Create Document Component
function  MyDocument (data) {
  let x =data.header
  const {addToast} = CustomToast();
  const message = `All House Cover Page Generated by user ${window.localStorage.getItem('user_email')} amounting to INR ${Number(x['total_price']).toLocaleString('en-IN')}`
  addToast({title: 'Success', description: 'All House Cover Page Generated', status: 'success'})
  telegram(message)
 
  return(
  <Document title={'All House Cover Page'}>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.section}>House Summary</Text>
		<Text style={styles.section}>Prikaway Private Limited</Text>
    <Text style={styles.section}>Unit: Tuck Shop {x['school_name']}</Text>
    <Text style={styles.section}>Customer Care: care@prikaway.com, +91-8002003353  GSTIN: 10AAKCP3572D1ZR</Text>
    <Text>&nbsp;</Text>
    <AllHouseCoverPDFTableHeader/>
    <AllHouseCoverPDFTableRows houseData={data.houseData}/>
    <Text>&nbsp;</Text>
    <View style={styles.totalsection}>
    <Text>Total Number of Items: {x['item_quantity']}</Text>
    <Text>Total Amount Payable: {Number(x['total_price']).toLocaleString('en-IN')}</Text>
    <Text>Total Amount Payable(In Words): {x['word_amount']}</Text>
    </View>
    <Text>&nbsp;&nbsp;</Text>
		<Text style={styles.footer}>All prices are in INR and inclusive of GST</Text>
    </View>
    </Page>
  </Document>
);
  }

export default function AllHouseCoverPDF(props) {
  //const header = JSON.parse(props)
  //const ert= useContext(StudentInvoiceContext)
  const ter = props.AllHouseCoverData
  var headerData= ter['header']
  var houseData = JSON.parse(ter['data'])
  
    return(    
    <Center>
    <PDFViewer height={'900em'} width={'1000em'}>
        <MyDocument header={headerData} houseData={houseData}/>
      </PDFViewer>
    </Center>
    )
}