import React , {useState,} from 'react';
import { useNavigate, } from "react-router-dom";
import {
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  CircularProgress,
  Image,
  Center,
} from '@chakra-ui/react';
import telegram from './Telegram';
import {CustomToast} from './Toast';
export default function LoginForm(props) {
  const {addToast} = CustomToast();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate('/home');
  };
  
  const handleSubmit = async event => {
    event.preventDefault();
    setIsLoading(true);  //change the login api address after dev
    const a = await (await fetch(process.env.REACT_APP_API+`/login?username=${email}&password=${password}`)).json();
    
    if (a.error === false){
      const b= JSON.parse(a.data)
      const c=b[0]
      setIsLoading(false);
      props.onClose(true)
      props.setSchoolData({
        userID: c.user_id,
        schoolID: c.school_id,
        userName: c.user_name,
        userEmail: c.user_email,
        schoolName: c.school_name,
        schoolCode: c.school_code,
        picURL: c.pic_url,
        isLoggedIn: true
      })
      for (let x in c){
        localStorage.setItem(x, c[x]);
      }
      localStorage.setItem('isLoggedIn', true)
      localStorage.setItem('visited', true)
      addToast({title: 'Authenticated', description: 'Log in Successful', status: 'success'});
      telegram(`User ${email} logged in`); //ping login message on telegram
      navigateToHome();
    } else if (a.error === true){
      setIsLoading(false);
      addToast({title: 'Denied', description: a.message, status: 'error'});
      telegram(`User ${email} tried to log in but failed`) //ping login message on telegram

    }
  };

  
  
  return (
    <>
      <Center>
      <Image src='https://i.postimg.cc/GhJ9tzZh/logo-removebg-preview.png' h={'5em'} w={'6.5em'} objectFit='cover' /></Center>
      <Flex width="full" align="center" justifyContent="center" p={5}>
      <Box p={8} maxWidth="500px" borderWidth={1} borderRadius={8} boxShadow="lg">
        <Box textAlign="center">
          <Heading as='h3' size='sm' noOfLines={1} fontWeight={500}>Enter Your Details</Heading>
        </Box>
        <Box my={4} textAlign="left">
        <form onSubmit={handleSubmit}>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="email@example.com"
              size="lg"
              onChange={event => setEmail(event.currentTarget.value)}
            />
          </FormControl>
          <FormControl isRequired mt={6}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="*******"
              size="lg"
              onChange={event => setPassword(event.currentTarget.value)}
            />
          </FormControl>
          <Button
            colorScheme='facebook'
            variant="solid"
            type="submit"
            width="full"
            mt={4}
          >
            {isLoading ? (<CircularProgress isIndeterminate size="24px" color="teal" />) :
            ('Log In')}
          </Button>
        </form>
        </Box>
      </Box>
    </Flex>
    </>
  );
}
