import { Heading, Tabs, TabList, TabPanels, Tab, TabPanel, VStack, Table, Thead, Tr, Th, Tbody, Td, NumberInput, NumberInputField, Button, HStack, CircularProgress, FormLabel, Input, Text, FormControl, Center, Skeleton, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { MdLocalGroceryStore } from "react-icons/md";
import { IoArrowForward } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
export default function RaashanShop(props){
    const navigate = useNavigate();
    const [raashanProdList, setraashanProdList] = useState([]);
    const [nonVegProdList, setnonVegProdList] = useState([]);
    const [fruitsVeggiesProdList, setfruitsVeggiesProdList] = useState([]);
    const [disabled, setDisabled] = useState(false); // for number input fields
    const [isLoading, setIsLoading] = useState(false); // for circular progress in the confirm button
    const [finalProductList] = useState({});
    const [skeleton, setSkeleton] = useState(true);
    var [headerState, setHeaderState] = useState({
        start_date: "",
        end_date:"",
        inv_date: "",
        tender_name:"",
        });

    useEffect(() => {
        const fetchData = async () => {
            //fetch the raashan prod list
          const response = await fetch(process.env.REACT_APP_API+`/db_raashan_products_search?tender=1`);
          const newData = await response.json();
          setraashanProdList(newData)

          //fetch the nonveg prod list
          const nonvegPing = await fetch(process.env.REACT_APP_API+`/db_raashan_products_search?tender=2`);
          const nonVeg = await nonvegPing.json();
          setnonVegProdList(nonVeg)

          //fetch the fruits prod list
          const fruitsPing = await fetch(process.env.REACT_APP_API+`/db_raashan_products_search?tender=3`);
          const fruits = await fruitsPing.json();
          setfruitsVeggiesProdList(fruits)
          setSkeleton(false)
        }; 
        fetchData();
    },[props.schoolID]);
    
    const raashanChangeInput = (e, index) => {
        const { name, value } = e.target
        raashanProdList[index][name]=value;
        raashanProdList[index]['total_price']=Math.round((raashanProdList[index]['rate']+raashanProdList[index]['gst_amount'])*raashanProdList[index]['item_quantity']*100)/100
    }

    const nonVegChangeInput = (e, index) => {
        const { name, value } = e.target
        nonVegProdList[index][name]=value;
        nonVegProdList[index]['total_price']=Math.round((nonVegProdList[index]['rate']+nonVegProdList[index]['gst_amount'])*nonVegProdList[index]['item_quantity']*100)/100
    }

    const fruitsVeggiesChangeInput = (e, index) => {
        const { name, value } = e.target
        fruitsVeggiesProdList[index][name]=value;
        fruitsVeggiesProdList[index]['total_price']=Math.round((fruitsVeggiesProdList[index]['rate']+fruitsVeggiesProdList[index]['gst_amount'])*fruitsVeggiesProdList[index]['item_quantity']*100)/100
    }

    const handleSubmit = (type) => {
        setDisabled(true)
        var price=0;
        switch(type){
        case 1:
        raashanProdList.map((value) => value['total_price'] ?  price+=value['total_price']: price);
        break;
        case 2:
        nonVegProdList.map((value) => value['total_price'] ?  price+=value['total_price']: price);
        break;
        case 3:
        fruitsVeggiesProdList.map((value) => value['total_price'] ?  price+=value['total_price']: price);
        break;
        default:
        console.log("No Match")
        }
        price=Math.round(price*100)/100
        setHeaderState({...headerState, total_price:price, tender:type, invoice_no:Math.random()*Math.pow(10,18)})
        
    }

    const goBack = () => {
        setDisabled(false);
        setIsLoading(false);
    }

    const persistData = (type)=>{
        
        setIsLoading(true);
        // performing the type of operation
        switch(type){
            case 1:
                headerState['tender_name']="Raashan";
                for (let x in raashanProdList){
                    if(raashanProdList[x].hasOwnProperty('item_quantity') === true)
                        finalProductList[x]=raashanProdList[x];
                    else
                        continue;
                }
            break;

            case 2:
                headerState['tender_name']="Non-Veg";
                for (let x in nonVegProdList){
                    if(nonVegProdList[x].hasOwnProperty('item_quantity') === true)
                        finalProductList[x]=nonVegProdList[x];
                    else
                        continue;
                }
            break;

            case 3:
                headerState['tender_name']="Fruits & Vegetables";
                for (let x in fruitsVeggiesProdList){
                    if(fruitsVeggiesProdList[x].hasOwnProperty('item_quantity') === true)
                        finalProductList[x]=fruitsVeggiesProdList[x];
                    else
                        continue;
                }
            break;

            default:
                console.log("No Match");
            break;
        }
        

        const savetodb = async () =>{ 
            
            const response = await fetch(process.env.REACT_APP_API+"/db_save_raashan_bill_details", {
            
            // Adding method type
            method: "POST",
            
            // Adding body or contents to send
            body: JSON.stringify({
                header: headerState,
                products: finalProductList,
            }),
            
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
        const newData = await response.json();
        // Capturing the Amount in Words from backend
        headerState['word_amount']=newData['word_amount'];
        props.setRaashanShopBill({'headers':headerState, 'products':finalProductList});
        setIsLoading(false);
        navigate('/home/raashanShop/viewInvoice');
        }
        savetodb();
        
    }
    return(
        <Center>
            <VStack spacing={10} borderWidth={1} borderRadius={8} boxShadow={'lg'} p={5}>
                <Heading as={'h3'} size={'lg'}><Icon as={MdLocalGroceryStore} boxSize={6}/>&nbsp; Generate Raashan Shop Bill</Heading>
                <Center>
                <FormControl isRequired>
                    <HStack spacing={20}>
                        <HStack>
                            <FormLabel w={'10em'} fontSize={'xs'}>Start Date</FormLabel>
                            <Input isDisabled={disabled} type='date' name='start_date' onChange={(e)=> setHeaderState({ ...headerState,start_date:e.target.value })}/>    
                        </HStack>

                        <HStack>
                            <FormLabel w={'10em'} fontSize={'xs'}>End Date</FormLabel>
                            <Input isRequired isDisabled={disabled} type='date' name='end_date' onChange={(e)=> setHeaderState({ ...headerState,end_date:e.target.value })}/>    
                        </HStack>

                        <HStack>
                            <FormLabel w={'10em'} fontSize={'xs'}>Invoice Date</FormLabel>
                            <Input isRequired isDisabled={disabled} type='date' name='inv_date' onChange={(e)=> setHeaderState({ ...headerState,inv_date:e.target.value })}/>    
                        </HStack>
                    </HStack>
                </FormControl>
                </Center>
                <Tabs  variant='enclosed'>
                    <TabList>
                        <Tab>Raashan</Tab>
                        <Tab>Non-Veg</Tab>
                        <Tab>Fruits & Vegetables</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                        <Skeleton isLoaded={!skeleton}>
                            <VStack spacing={5}>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>Sr.</Th>
                                        <Th>Item Name</Th>
                                        <Th>Unit</Th>
                                        <Th>Price</Th>
                                        <Th>GST Amount</Th>
                                        <Th>Enter Quantity</Th>
                                        <Th>Total Price</Th>
                                    </Tr>
                                </Thead>
                                
                                <Tbody>
                                    {raashanProdList.map((value,index)=> {
                                        return(
                                            <Tr key={index}>
                                                <Th>{index+1}</Th>
                                                <Th>{value['item_name']}</Th>
                                                <Th>{value['item_unit']}</Th>
                                                <Th>{value['rate']}</Th>
                                                <Th>{value['gst_amount']}</Th>
                                                <Td>
                                                    <NumberInput isDisabled={disabled}>
                                                        <NumberInputField name= "item_quantity" onChange={(e)=>raashanChangeInput(e,index)}/>
                                                    </NumberInput>
                                                </Td>
                                                {disabled? <Th>&#8377;&nbsp;{value['total_price']}</Th>: <Th>-</Th>}
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                            {disabled?
                                        <VStack>
                                                <HStack>
                                                    <Text m={2}>Total Amount &nbsp;&nbsp;&#8377;&nbsp;{headerState['total_price']}</Text>
                                                    <Text>Bill No: &nbsp;{headerState['invoice_no']}</Text>
                                                </HStack>
                                        <HStack>
                                            <Button variant={'solid'} colorScheme="pink" onClick={goBack} leftIcon={<IoMdArrowBack/>}>Go Back</Button> 
                                            <Button variant={'solid'} colorScheme="whatsapp" onClick={() => persistData(1)} leftIcon={<MdLocalGroceryStore/>}>{isLoading?(<CircularProgress isIndeterminate size="24px" color="teal" />):('Create Bill')}</Button>
                                        </HStack>
                                        </VStack> 
                                    :<Button variant={'solid'} colorScheme="telegram" onClick={()=>handleSubmit(1)} rightIcon={<IoArrowForward/>}>Confirm</Button>}
                            </VStack> 
                            </Skeleton>                           
                        </TabPanel>

                        
                        <TabPanel>
                        <Skeleton isLoaded={!skeleton}>
                            <VStack spacing={5}>
                            <Table>    
                                <Thead>
                                    <Tr>
                                        <Th>Sr.</Th>
                                        <Th>Item Name</Th>
                                        <Th>Unit</Th>
                                        <Th>Price</Th>
                                        <Th>GST Amount</Th>
                                        <Th>Enter Quantity</Th>
                                        <Th>Total Price</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {nonVegProdList.map((value,index)=> {
                                        return(
                                            <Tr key={index}>
                                                <Td>{index+1}</Td>
                                                <Td>{value['item_name']}</Td>
                                                <Td>{value['item_unit']}</Td>
                                                <Td>{value['rate']}</Td>
                                                <Td>{value['gst_amount']}</Td>
                                                <Td>
                                                    <NumberInput isDisabled={disabled}>
                                                        <NumberInputField name= "item_quantity" onChange={(e)=>nonVegChangeInput(e,index)}/>
                                                    </NumberInput>
                                                </Td>
                                                {disabled? <Td>&#8377;&nbsp;{value['total_price']}</Td>: null}
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>  
                            {disabled?
                                        <VStack>
                                                <HStack>
                                                    <Text m={2}>Total Amount &nbsp;&nbsp;&#8377;&nbsp;{headerState['total_price']}</Text>
                                                    <Text>Bill No: &nbsp;{headerState['invoice_no']}</Text>
                                                </HStack>
                                        <HStack>
                                            <Button variant={'solid'} colorScheme="pink" onClick={goBack} leftIcon={<IoMdArrowBack/>}>Go Back</Button> 
                                            <Button variant={'solid'} colorScheme="whatsapp" onClick={() => persistData(2)} leftIcon={<MdLocalGroceryStore/>}>{isLoading?(<CircularProgress isIndeterminate size="24px" color="teal" />):('Create Bill')}</Button>
                                        </HStack>
                                        </VStack> 
                                    :<Button variant={'solid'} colorScheme="telegram" onClick={()=>handleSubmit(2)} rightIcon={<IoArrowForward/>}>Confirm</Button>}
                            </VStack>
                            </Skeleton>
                        </TabPanel>
                    
                       
                        <TabPanel>
                            <Skeleton isLoaded={!skeleton}>
                            <VStack spacing={5}>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>Sr.</Th>
                                        <Th>Item Name</Th>
                                        <Th>Unit</Th>
                                        <Th>Price</Th>
                                        <Th>GST Amount</Th>
                                        <Th>Enter Quantity</Th>
                                        <Th>Total Price</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {fruitsVeggiesProdList.map((value,index)=> {
                                        return(
                                            <Tr key={index}>
                                                <Td>{index+1}</Td>
                                                <Td>{value['item_name']}</Td>
                                                <Td>{value['item_unit']}</Td>
                                                <Td>{value['rate']}</Td>
                                                <Td>{value['gst_amount']}</Td>
                                                <Td>
                                                    <NumberInput isDisabled={disabled}>
                                                        <NumberInputField name= "item_quantity" onChange={(e)=>fruitsVeggiesChangeInput(e,index)}/>
                                                    </NumberInput>
                                                </Td>
                                                {disabled? <Td>&#8377;&nbsp;{value['total_price']}</Td>: null}
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                            {disabled?
                                        <VStack>
                                                <HStack>
                                                    <Text m={2}>Total Amount &nbsp;&nbsp;&#8377;&nbsp;{headerState['total_price']}</Text>
                                                    <Text>Bill No: &nbsp;{headerState['invoice_no']}</Text>
                                                </HStack>
                                        <HStack>
                                            <Button variant={'solid'} colorScheme="pink" onClick={goBack} leftIcon={<IoMdArrowBack/>}>Go Back</Button> 
                                            <Button variant={'solid'} colorScheme="whatsapp" onClick={() => persistData(3)} leftIcon={<MdLocalGroceryStore/>}>{isLoading?(<CircularProgress isIndeterminate size="24px" color="teal" />):('Create Bill')}</Button>
                                        </HStack>
                                        </VStack> 
                                    :<Button variant={'solid'} colorScheme="telegram" onClick={()=>handleSubmit(3)} rightIcon={<IoArrowForward/>}>Confirm</Button>}   
                            </VStack>
                        </Skeleton>                            
                        </TabPanel>
                    </TabPanels>
                </Tabs>    
            </VStack>
        </Center>    
        
    )
}