import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '##D5DBDB'
const styles = StyleSheet.create({
    container1: {
        flexDirection: 'row',
        borderWidth: 1,
        borderStyle: 'dashed',
        alignItems: 'center',
        textAlign: 'center',
        flexGrow: 1,
        height: 24,
    },
    name: {
        width: '35%',
        fontStyle: 'Bold',
        margin : '4px',
        borderRightWidth: 1,
        borderColor:borderColor,
        textAlign: 'left',
    },
    class:{
        width: '15%',
        fontStyle: 'Bold',
        margin : '4px',
        borderRightWidth: 1,
        borderColor:borderColor,
        textAlign: 'left',
    },
    rollNo: {
        width: '20%',
        margin : '4px',
        borderRightWidth: 1,
        borderColor:borderColor,
    },
    house: {
        width: '30%',
        margin : '4px',
        textAlign: 'left'

    },
    date: {
        width: '50%',
        borderRightWidth: 1,
        borderColor:borderColor,
        textAlign: 'left',
    },
    invNo: {
        width: '50%',
    },
    container2: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        height: 24,

    }
  });

  function InvoiceSearchHeader (props) {
    const header = props.header
    var date = new Date(header['date_of_purchase'])
    const formattedDate = `${(date.getDate() < 10 ? '0':'')+date.getDate()}-${((date.getMonth() + 1) < 10 ? '0':'')+(date.getMonth() + 1)}-${date.getFullYear()}`;
  
    return(
    <>
    <View style={styles.container1}>
        <Text style={styles.name}>Name: {header['student_name']}</Text>
        <Text style={styles.class}>Class: {header['class']}</Text>
        <Text style={styles.rollNo}>Roll No: {header['roll_no']}</Text>
        <Text style={styles.house}>House: {header['house_name']}</Text>
    </View>
    <View style={styles.container2}>
        <Text style={styles.date}>Invoice Date: {formattedDate}</Text>
        <Text style={styles.invNo}>Invoice No: {header['bill_no']}</Text>
    </View>
    </>
    )
  };
  
  export default InvoiceSearchHeader