import { Flex, HStack, Link, Image, Spacer, Box, Center } from "@chakra-ui/react";
import ThemeToggler from "./ThemeToggler";
import StatData from "../Homepage/StatData";
import UserMenu from "../Homepage/UserMenu";
import LoginDrawer from "./LoginDrawer";
import NavMenu from "../Homepage/NavMenu";

export default function Header(props){
    return(
        <Flex as='header' position={'relative'} top={'0'} w={'100%'} p={1}>
              <HStack alignItems={'center'} spacing={2}>
                <Link href={window.localStorage.getItem('isLoggedIn') ? '/home' : '/'}>
                  <Image src='https://i.postimg.cc/GhJ9tzZh/logo-removebg-preview.png' h={'5em'} w={'6.5em'} objectFit='cover'/>
                </Link>
                {localStorage.getItem('isLoggedIn') ? <NavMenu/>: null}               
              </HStack>
              <Spacer/>
              <Box mr={2} >
              {localStorage.getItem('isLoggedIn') ? <HStack spacing={0}>                  
                                                    <Center>
                                                      <StatData/>
                                                      <UserMenu/>
                                                      </Center>
                                                    </HStack>
                                                : (
                                                    <HStack>
                                                      <LoginDrawer setSchoolData={props.setSchoolData}/>
                                                      <ThemeToggler />
                                                    </HStack> )
                }
                
              </Box>
            </Flex>
            
    )
}