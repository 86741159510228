import { Center} from "@chakra-ui/react";
import { Page, Text, View, Document, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import RaashanPDFHeader from "./RaashanPDFHeader";
import RaashanPDFTableHeader from "./RaashanPDFTableHeader";
import RaashanPDFTableProducts from "./RaashanPDFTableProducts";
import telegram from "../MainSite/Telegram";
import { CustomToast } from "../MainSite/Toast";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#E4E4E4',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily:'Courier'

  },
  section: {
    margin: 'auto',
    fontSize: 12,
  },
  footer: {
    margin: 'auto',
	  fontSize: 10
  },
  totalsection:{
    borderWidth: 1,
    borderStyle: 'dashed',
  }
  
});


// Create Document Component
function  MyDocument (data) {
  const {addToast} = CustomToast();
  // Displaying the toast
  addToast({title: 'Success', description: 'Invoice Created Successfully', status: 'success'})
  let x =data.header 
  const message = `Sainik School Gopalganj
  ${x['tender_name']} Tender Bill Generated
Invoice No : ${x['invoice_no']}
Total Price : ${x['total_price']}
All details saved to Database.`
    telegram(message) //Pinging on telegram

    return(
    <Document title={x['invoice_no']}>
        <Page size="A4" style={styles.page}>
        <View style={styles.section}>
            <Text style={styles.section}>{x['tender_name']}&nbsp;Tender Bill</Text>
            <Text style={styles.section}>Prika Way Private Limited</Text>
        <Text style={styles.section}>Unit: Raashan Shop Sainik School Gopalganj</Text>
        <Text style={styles.section}>Customer Care: care@prikaway.com, +91-8002003353  GSTIN: 10AAKCP3572D1ZR</Text>
        <Text>&nbsp;</Text>
            <RaashanPDFHeader header={data.header}/>
        <Text>&nbsp;&nbsp;</Text>
        <RaashanPDFTableHeader/>
        <RaashanPDFTableProducts products={data.products}/>
        <Text>&nbsp;</Text>
        <View style={styles.totalsection}>
        <Text>Total Amount Payable: {Number(x['total_price']).toLocaleString('en-IN')}</Text>
        <Text>Total Amount Payable(In Words): {x['word_amount']}</Text>
        </View>
        <Text>&nbsp;&nbsp;</Text>
            <Text style={styles.footer}>All prices and GST are in INR</Text>
        </View>
        </Page>
    </Document>
    );
    }


export default function RaashanShopBillPDF(props) {
    
    const ter = props.raashanShopBill;
    var prodArray=Object.values(ter['products']) //converting the obejct to array
    return(
    <Center>
    <PDFViewer height={'900em'} width={'1000em'}>
        <MyDocument header={ter['headers']} products={prodArray}/>
      </PDFViewer>
    </Center>
    )
}