export default async function telegram(message){
    
    await fetch(process.env.REACT_APP_MESSENGER, {
            
            // Adding method type
            method: "POST",
            
            // Adding body or contents to send
            body: JSON.stringify({
                'message': message
              }),
            
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
      
  }