import React, {useState, useEffect} from 'react';
import { Routes, Route, useNavigate, redirect } from "react-router-dom";
import HomePage from './components/Homepage/HomePage';
import Footer from './components/MainSite/Footer';
import StudentInvoiceInputForm from './components/StudentInvoice/StudentInvoiceInputForm';
import StudentInvoiceSearchView from './components/StudentInvoice/StudentInvoiceSearchView';
import EditStudentInvoiceButton from "./components/StudentInvoice/EditStudentInvoiceButton";
import PrincipalBill from './components/PrincipalBill/PrinciaplBill';
import PrinciaplBillPDF from './components/PrincipalBill/PrincipalBillPDF';
import CoverPageLanding from './components/CoverPages/CoverPageLanding';
import AllHouseCoverPDF from './components/CoverPages/AllHouseCoverPDF';
import HouseCoverPDF from './components/CoverPages/HouseCoverPDF';
import StudentInvoicePDF from './components/StudentInvoice/StudentInvoicePDF';
import RaashanShop from './components/RaashanShop/RaashanShop';
import RaashanShopBillPDF from './components/RaashanShop/RaashanShopBillPDF';
import {
  ThemeProvider,
  theme,
  ColorModeProvider,
  CSSReset,
  ChakraProvider,
} from '@chakra-ui/react';
import Activate from './components/MainSite/Activate';
import Dashboard from './components/Dashboard/Dashboard';
import Inventory from './components/Inventory/Inventory';
import Content from './components/MainSite/Content';
import Cookie from './components/MainSite/Cookie';
import About from './components/MainSite/About';
import Settings from './components/MainSite/Settings';
import Header from './components/MainSite/Header';

export default function App() {
  Activate();
  const[schoolData, setSchoolData] = useState({
    userID:'',
    schoolID:'',
    userName:'',
    userEmail:'',
    schoolName:'',
    schoolCode:'',
    picURL:'',
    isLoggedIn:false
  })
  const[studentInvSearchData, setStudentInvSearchData] =useState('');
  const[studentInvSearchCriteria, setStudentInvSearchCriteria] = useState('');
  const[PrincipalInvSearchData, setPrincipalInvSearchData] =useState('');
  const[AllHouseCoverData, setAllHouseCoverData] =useState('');
  const[createStudentInvoiceData, setCreateStudentInvoiceData] = useState('');
  const[raashanShopBill, setRaashanShopBill] = useState('');
  const navigate = useNavigate();
  
  useEffect(() => {
    redirect('/home/dashboard')
    if (localStorage.getItem('isLoggedIn') === undefined)
      localStorage.setItem('isLoggedIn', false);

    if (localStorage.getItem('isLoggedIn') === 'true' && window.location.pathname === '/')
      navigate('/home')

});

  return (
    
    <ChakraProvider>
    <ThemeProvider theme={theme}>
      <ColorModeProvider>
        <CSSReset/>
          <Cookie/>
          <Header setSchoolData={setSchoolData}/>
          
            
            <Routes>  
              <Route path='/home' element={<HomePage setStudentInvSearchData={setStudentInvSearchData} setStudentInvSearchCriteria={setStudentInvSearchCriteria} />}></Route>
              <Route path='/home/invoice/student/createInvoice' element={<StudentInvoiceInputForm schoolData={schoolData} setCreateStudentInvoiceData={setCreateStudentInvoiceData}/>}></Route>
              <Route path='/home/invoice/student/viewInvoice' element={<StudentInvoiceSearchView studentInvSearchData={studentInvSearchData}/>}></Route>
              <Route path='/home/invoice/student/editInvoice' element={<EditStudentInvoiceButton studentInvSearchData={studentInvSearchData} schoolData={schoolData} studentInvSearchCriteria={studentInvSearchCriteria} />}></Route>
              <Route path='/home/invoice/principal/createInvoice' element={<PrincipalBill schoolID={schoolData.schoolID} setPrincipalInvSearchData={setPrincipalInvSearchData}/>}></Route>
              <Route path='/home/invoice/principal/viewInvoice' element={<PrinciaplBillPDF schoolID={schoolData.schoolID} PrincipalInvSearchData={PrincipalInvSearchData}/>}></Route>
              <Route path='/home/coverPages' element={<CoverPageLanding schoolID={schoolData.schoolID} setAllHouseCoverData={setAllHouseCoverData} />}></Route>
              <Route path='/home/coverPage/allHouseCover/view' element={<AllHouseCoverPDF schoolID={schoolData.schoolID} AllHouseCoverData={AllHouseCoverData} />}></Route>
              <Route path='/home/coverPage/HouseCover/view' element={<HouseCoverPDF schoolID={schoolData.schoolID} AllHouseCoverData={AllHouseCoverData} />}></Route>
              <Route path='/home/invoice/student/createInvoice/view' element={<StudentInvoicePDF createStudentInvoiceData={createStudentInvoiceData}/>}></Route>
              <Route path='/home/raashanShop' element={<RaashanShop setRaashanShopBill={setRaashanShopBill}/>}></Route>
              <Route path='/home/raashanShop/viewInvoice' element={<RaashanShopBillPDF raashanShopBill={raashanShopBill}/>}></Route>
              <Route path='/home/dashboard' element={<Dashboard/>}></Route>
              <Route path='/home/inventory' element={<Inventory/>}></Route>
              <Route path='/home/about' element={<About/>}></Route>
              <Route path='/home/settings' element={<Settings/>}></Route>
            </Routes>  
          

          {localStorage.getItem('isLoggedIn') ? null : <Content/>}
          
          <Footer/>
      </ColorModeProvider>
    </ThemeProvider>
    </ChakraProvider>
  );
}

