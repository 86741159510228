import { Center, Box, Heading, HStack, Radio, RadioGroup, Icon, Button, Badge, VStack, Input, FormLabel, CircularProgress } from "@chakra-ui/react";
import ChangeTcLeaveButton from "../StudentInvoice/ChangeTcLeaveButton";
import DeleteInvoiceButton from "../StudentInvoice/DeleteInvoiceButton";
import { useState } from "react";
import { CustomToast } from "../MainSite/Toast";
import { useNavigate } from "react-router-dom";
import { FaFileInvoice, FaRegEdit, FaSearch } from "react-icons/fa";
import { Checkmark } from 'react-checkmark';
import { CiViewTimeline } from "react-icons/ci";
import { RxReset } from "react-icons/rx";

export default function SearchBox(props){
    const navigate = useNavigate();
    const {addToast} = CustomToast();
    const [isLoading, setIsLoading] = useState(false);
    const [InvFound, SetInvFound] = useState(false);
    const [tcLeave, setTcLeave] =useState(''); //to capture the badge data
    const [searchFormState, setSearchFormState] =useState({
        invNo: "",
        invDate: "",
        invType: "",
    })

    const handleSubmit = async event => {
        
        event.preventDefault();  
        if(searchFormState.invDate === '')
            addToast({title: 'Enter Date', description: 'Required Field Missing', status: 'warning'});
        if (searchFormState.invType === '1' && searchFormState.invDate !== ''){  
            setIsLoading(true);
            const a = await (await fetch(process.env.REACT_APP_API+`/db_search_student_invoice?inv_no=${searchFormState.invNo}&date_of_purchase=${searchFormState.invDate}`)).json();
            if (a.found === false){
                addToast({title: 'No Results Found', description: 'Invoice Does Not Exist in the Database', status: 'error'});
                setIsLoading(false)
            } else {
                SetInvFound(true);
                //setting up the tc_leave data in badge
                var b = JSON.parse(a.headers)
                b=b[0]
                setTcLeave(b['tc_leave'])
                props.setStudentInvSearchData(a);
                props.setStudentInvSearchCriteria({'invNo': searchFormState.invNo, 'invDate': searchFormState.invDate})
                setIsLoading(false);
            }
        }else if(searchFormState.invType === '2'){
            addToast({title: 'Unable to Search', description: 'Please check again later', status: 'error'});
            handleClear();
        }
        }
        const navigateToInvoice= () => {
            navigate('/home/invoice/student/viewInvoice');
        };
        const navigateToEditInvoice= () => {
            navigate('/home/invoice/student/editInvoice');
        };
        const handleClear= () => {
            setSearchFormState({
                invNo: "",
                invDate: "",
                invType: "",
            });
            SetInvFound(false);
            setIsLoading(false);
        }
    

    return(
        <Center>
                <Box borderWidth={1} borderRadius={8} boxShadow="lg">
                    <Center m={4}>
                        <Heading as='h3' size='lg'><Icon boxSize={6} as={FaFileInvoice}/>&nbsp; Search Invoices</Heading>
                    </Center>
                    <VStack spacing={5} m={5}>
                            <Center>          
                                <RadioGroup value={searchFormState.invType} >
                                    <HStack >
                                    <FormLabel fontSize={'xs'}>Invoice Type</FormLabel>
                                        <Radio name='invType' value='1' onChange={(e)=> setSearchFormState({ ...searchFormState, invType:e.target.value })}>Student</Radio>
                                        <Radio name='invType' value='2' onChange={(e)=> setSearchFormState({ ...searchFormState, invType:e.target.value })}>Principal</Radio>
                                    </HStack>
                                </RadioGroup>
                            </Center>  
                <HStack spacing={5}>
                    
                    <HStack> 
                        <FormLabel fontSize={'xs'} w={'150px'}>Invoice Number</FormLabel>
                        <Input type='text' placeholder="Enter Invoice Number" onChange={(e)=> setSearchFormState({ ...searchFormState, invNo:e.target.value })} value={searchFormState.invNo} name='invNo'/>
                    </HStack>
                    <HStack>    
                        <FormLabel fontSize={'xs'}>Date</FormLabel>
                        <Input type='date'placeholder="Enter Date" onChange={(e)=> setSearchFormState({ ...searchFormState, invDate:e.target.value })} value={searchFormState.invDate} name='invDate'/>
                    </HStack>
                    
                    <Button onClick={handleSubmit} colorScheme='telegram' variant='solid' isDisabled={InvFound || isLoading} leftIcon={isLoading? null : <FaSearch/>}>{isLoading? <CircularProgress isIndeterminate size="24px" color="teal" /> : 'Search' }</Button> 
                    <Button onClick={handleClear} colorScheme="pink" leftIcon={<RxReset/>}>Reset</Button>
                    </HStack>
                    {InvFound?(<VStack><HStack mb={2}><Checkmark size='small'/>
                                        <Heading size='xs'>Invoice Found! </Heading>
                                        {tcLeave?(<Badge colorScheme='red'>TC/Leave status is YES</Badge>):(<Badge colorScheme='green'>TC/Leave status is NO</Badge>)}
                                         </HStack>
                                         <HStack>
                                            <ChangeTcLeaveButton invNo={searchFormState.invNo} invDate={searchFormState.invDate} setTcLeave={setTcLeave} tcLeave={tcLeave}/>
                                            <Button onClick={navigateToInvoice} colorScheme='green' variant='outline' leftIcon={<CiViewTimeline/>}>View Invoice</Button>
                                            <Button onClick={navigateToEditInvoice} colorScheme='yellow' variant='outline' leftIcon={<FaRegEdit/>} >Edit Invoice</Button>
                                            <DeleteInvoiceButton invNo={searchFormState.invNo} invDate={searchFormState.invDate} handleClear={handleClear} />
                                        </HStack>
                                </VStack>):(null)}
                            
                    </VStack>
                </Box>
            </Center>
            
    )
}