import React, {Fragment} from 'react';
import {Text, View } from '@react-pdf/renderer';
import {styles} from './StudentInvoiceSearchTableHeader';
  
function SearchInvoiceTableProducts (props) {
    
    var counter=0
    const rows = props.products.map( item => 
        <View style={styles.productRows} key={item['product_name']}>
            <Text style={styles.sr}>{counter+=1}</Text>
            <Text style={styles.description}>{item['product_name']}</Text>
            <Text style={styles.size}>{item['size']}</Text>
            <Text style={styles.qty}>{item['item_quantity']}</Text>
            <Text style={styles.rate}>{Number(item['product_price']).toLocaleString('en-IN')}</Text>
            <Text style={styles.price}>{Number(item['total_price']).toLocaleString('en-IN')}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  export default SearchInvoiceTableProducts;