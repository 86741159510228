import { Center, Heading, VStack, Input, HStack, Box, RadioGroup, Radio, Button, Table, Td, Th, Tr, Tbody, Thead, Icon, FormLabel, } from "@chakra-ui/react";
import React, { useState, } from "react";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { MdCalculate } from "react-icons/md";
import { RxReset } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

export default function PrincipalBill(props){
    var [headerData,setHeaderData] =useState({});
    var [productData,setProductData] =useState({});
    const navigate = useNavigate();
    const [searchFormState, setSearchFormState] =useState({
        startDate: "",
        endDate: "",
        tcType: true,
        
    })
    const [found,setFound] = useState(false)

    const handleSubmit = async event => {
        event.preventDefault();  
        
            const a = await (await fetch(process.env.REACT_APP_API+`/db_product_pivot_principal_bill?start_date=${searchFormState.startDate}&end_date=${searchFormState.endDate}&school_id=${window.localStorage.getItem('school_id')}&tc_leave=${searchFormState.tcType}`)).json();
            if (a.found === true){
                setFound(true);
                setHeaderData(a.header);
                setProductData(JSON.parse(a.products));
                props.setPrincipalInvSearchData(a);
            }
                      
        }

        const handleClear= () => {
            setSearchFormState({
                startDate: "",
                endDate: "",
                tcType: false,
                
            })
            setFound(false)
        }
        //creating the PDF
        const createPDF = () =>{
            
            navigate('/home/invoice/principal/viewInvoice')
        }



    return(
        <Center>
            <VStack>
                <Box m={5} borderWidth={1} borderRadius={8} boxShadow={'lg'} mb={found?'2em':'20em'} p={5}>
                    <Center>
                    <VStack>
                    <Heading as={'h3'} size={'lg'}><Icon as={FaMoneyBillTrendUp} boxSize={6}/>&nbsp; Generate Bill To School Principal</Heading>

                    <Heading as='h5' size={'md'} mt={5}>Enter Search Criteria</Heading>
                    <HStack spacing={10} m={5}>
                        <HStack>
                            <FormLabel w={'120px'} fontSize={'sm'}>Start Date</FormLabel>
                                <Input isRequired type="date"placeholder="Enter Date" onChange={(e)=> setSearchFormState({ ...searchFormState, startDate:e.target.value })} value={searchFormState.startDate} name='startDate'/>
                        </HStack>
                        <HStack>
                            <FormLabel w={'100px'} fontSize={'sm'}>End Date</FormLabel>
                            <Input isRequired type="date" placeholder="Enter Date" onChange={(e)=> setSearchFormState({ ...searchFormState, endDate:e.target.value })} value={searchFormState.endDate} name='endDate'/> 
                        </HStack>
                    </HStack>
                    <RadioGroup mb={2} value={searchFormState.tcType}>
                        <HStack>
                            <FormLabel fontSize={'sm'}>TC/Leave Status</FormLabel>
                            <Radio name='invType' value='False' onChange={(e)=> setSearchFormState({ ...searchFormState, tcType:e.target.value })}> No</Radio>
                            <Radio name='invType' value='True' onChange={(e)=> setSearchFormState({ ...searchFormState, tcType:e.target.value })}>Yes</Radio>
                        </HStack>
                    </RadioGroup>
                    
                    <HStack>
                    <Button mb={5} variant={'solid'} colorScheme="telegram" onClick={handleSubmit} isDisabled={found} leftIcon={<MdCalculate/>}>Calculate</Button>
                    <Button mb={5} variant={'solid'} colorScheme="pink" onClick={handleClear} leftIcon={<RxReset/>}>Reset</Button>
                    </HStack>
                    </VStack>
                    </Center> 
                </Box> 
                {found?(
            <>    
            <Table >
                <Thead>
                <Tr>
                    <Th>Bill Date</Th>
                    <Th>Invoice No.</Th>
                    <Th>Total Items</Th>
                    <Th>Total Price</Th>
                </Tr>
                </Thead>
                <Tbody>
                <Tr>
                    <Td>{headerData['bill_date']}</Td>
                    <Td>{headerData['inv_no']}</Td>
                    <Td>{parseInt(headerData['item_quantity']).toLocaleString('en-IN')}</Td>
                    <Td>&#8377;&nbsp;{parseInt(headerData['total_price']).toLocaleString('en-IN')}</Td>
                </Tr>
                </Tbody>
            </Table>
            <br/>
            <Table >
            <Thead>
            <Tr>
                <Th>Sr.</Th>
                <Th>Item Name</Th>
                <Th>Unit Price</Th>
                <Th>Item Quantity</Th>
                <Th>Total Price</Th>
            </Tr>
            </Thead>
            <Tbody>
            {Object.keys(productData).map((key,i)=> (<Tr key={i}>
                <Td>{i+1}</Td>
                <Td>{productData[key]['Product Name']}</Td>
                <Td>&#8377;&nbsp;{(productData[key]['Unit Price']).toLocaleString('en-IN')}</Td>
                <Td>{(productData[key]['Item Quantity']).toLocaleString('en-IN')}</Td>
                <Td>&#8377;&nbsp;{(productData[key]['Total Price']).toLocaleString('en-IN')}</Td>
            </Tr>))}
            
            </Tbody>
        </Table>
        <br/>
        <Button variant={'solid'} mb={100} onClick={createPDF} colorScheme="whatsapp" leftIcon={<FaMoneyBillTrendUp/>}>Confirm Details & Create PDF</Button>
        </>
        ):(null)} 
            </VStack>
        </Center>
        
    )
}