import {
    HStack,
    Center,
    Box,
    Heading,
    VStack,
    Skeleton,
    Table,
    Thead,
    Th,
    Tr,
    Td,
    Tbody,
    Icon
            } from '@chakra-ui/react';
import { useEffect, useState, } from 'react';
import { MdInventory } from 'react-icons/md';


export default function InventoryView(){

    const [inventoryData, setInventoryData] =useState('');
    const [loading,setLoading] =useState(window.localStorage.getItem('homePageSkeletonLoading') === null ?
        window.localStorage.setItem('homePageSkeletonLoading', true) : window.localStorage.getItem('homePageSkeletonLoading')
      );

    useEffect(() => {
         
        const fetchData = async () => {
          const response = await fetch(process.env.REACT_APP_API+`/db_view_inventory?school_id=${window.localStorage.getItem('school_id')}`);
          var newData = await response.json();
          setInventoryData(newData);
          setLoading(false);
        };
        fetchData();
      },[]);
    
    return(
        <Skeleton isLoaded={!loading} m={10}>
            <Box p={8}  borderWidth={1} borderRadius={8} boxShadow="lg">
                <Center>
                    <Heading as={'h4'} size={'lg'}><Icon boxSize={6} as={MdInventory}/>&nbsp; Current Inventory</Heading>
                </Center>
                <br/>
                <Center>
                
                <Table mb={10}>
                    <Thead>
                        <Tr>
                        <Th>Sr.</Th>
                        <Th textAlign={'center'}>Item Name</Th>
                        <Th>&nbsp;</Th>
                        <Th textAlign={'center'}>Stock Present</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {Object.keys(inventoryData).map((key,i)=> <Tr key={key}>
                        <Th>{i+1}</Th>
                        <Th>{key}</Th>
                        <Td>
                            <VStack>
                                <Th>Sizes</Th>
                                <Th>Quantity</Th>
                            </VStack>
                        </Td>
                        <Td>
                        <HStack>
                        {Object.keys(inventoryData[key]['stock_present']).map((key1,w) => 
                            <Table>
                            <Tr>
                            <Th textAlign={'center'}>{inventoryData[key]['stock_present'][key1]['size']}</Th> </Tr>
                            <Tr>
                            <Th color={'green'} textAlign={'center'}>{inventoryData[key]['stock_present'][key1]['quantity']}</Th>
                            </Tr>
                            </Table>                     
                            )}
                        </HStack>
                        </Td>
                        </Tr>)}
                    </Tbody>
                </Table>
                </Center>
                </Box>
            </Skeleton>
    )
}