import {
    Box,
    FormControl,
    FormLabel,
    Input,
    ChakraProvider,
    HStack,
    NumberInput,
    NumberInputField,
    Select,
    Button,
    Center,
    Table,
    Tr,
    Th,
    Td,
    Thead,
    Tbody,
    VStack,
    Text,
    Heading,
    Skeleton,
    CircularProgress,
    Icon,
      } from '@chakra-ui/react'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import telegram from '../MainSite/Telegram';
import { TbFileInvoice } from "react-icons/tb";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { CustomToast } from '../MainSite/Toast';
import InvoiceInputAlert from './StudentInvoiceInputAlerts';
const StudentInvoiceInputForm = (props) => {
    
    const navigate = useNavigate();

    var [headerState, setHeaderState] = useState({
        userID:window.localStorage.getItem('user_id'),
        schoolID:window.localStorage.getItem('school_id'),
        schoolName:window.localStorage.getItem('school_name'),
        student_name: "",
        class:"",
        roll_no: "",
        house_name: "",
        date_of_purchase: "",
        total_price:"",
        total_items:"",    
        });    
    const [loading,setLoading] = useState(true) //for skeleton
    const [houseList, setHouseList] = useState([]); // for capturing the house list from API call
    const [productList, setProductList] =useState([]); // for capturing the product list from API call
    const [disabled, setDisabled] = useState(false); //for disabling the input fields on button click
    const [finalProductList, setFinalProductList] = useState({}); // for storing the final prod list to be sent for making pdf
    const [isLoading, setIsLoading] = useState(false); // for circular progress in the confirm button
    useEffect(() => {
        const fetchData = async () => {
          const response = await fetch(process.env.REACT_APP_API+`/db_house_search?school_id=${window.localStorage.getItem('school_id')}`);
          const newData = await response.json();
          setHouseList(newData);
          const response1 = await fetch(process.env.REACT_APP_API+`/db_product_search?school_id=${window.localStorage.getItem('school_id')}`);
          const newData1 = await response1.json();
          const prodData = JSON.parse(newData1);
          setProductList(prodData);
          setLoading(false);  
        }; 
        fetchData();
    },[]);
    const [isVisible, setIsVisible] = useState(false);
    const [alertType, setAlertType] = useState(0);
    const {addToast} = CustomToast();

    const MakeItem = function(X) {
        return <option>{X}</option>;
    };

    function handleSubmit(){
        var price=0;
        var items=0;
        productList.map((value) => value['total_price'] ?  price+=value['total_price']: price);
        productList.map((value) => value['item_quantity'] ? items+=parseInt(value['item_quantity']): items);
        setHeaderState({...headerState, total_items:items, total_price:price, bill_no: 'PWPL/'+window.localStorage.getItem('school_code')+'/'+new Date(headerState.date_of_purchase).getFullYear()+'/'+(new Date(headerState.date_of_purchase).getMonth()+1)+'/'+headerState.roll_no})
        if (headerState['student_name'] === "" || headerState['class'] === "" || headerState['roll_no'] === "" || headerState['house_name'] === "" || headerState['date_of_purchase'] === "")
            addToast({title: 'Required Field(s) Missing Error', description: 'Fill in all the required fields marked with red *', status: 'error'})
        else
            setDisabled(true);
    }

    const goBack = () => {
        setDisabled(false);
        setFinalProductList({});
        setIsVisible(false)
    }

    const onChangeInput = (e, index) => {
        const { name, value } = e.target
        productList[index][name]=value;
        productList[index]['total_price']=productList[index]['product_price']*productList[index]['item_quantity']
    }

    const createInvoice = () =>{
        setIsLoading(true); //starting the circular progress
        for (let x in productList){
            
            if(productList[x].hasOwnProperty('item_quantity') === true)
                finalProductList[x]=productList[x];
            else
                continue;
        }
        // setting the create student invoice data from the main app props
        const persistData = async () => {
            const response= await fetch(process.env.REACT_APP_API+'/db_save_student_invoice', {
            
            // Adding method type
            method: "POST",
            
            // Adding body or contents to send
            body: JSON.stringify({
                header: headerState,
                products: finalProductList,
            }),
            
            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
                
        // Converting to JSON
        const newData = await response.json();

        if (newData['response'] === 200){
        // Capturing the Amount in Words from backend
        headerState['word_amount']=newData['word_amount'];
        
        // Updating the student invoice data
        props.setCreateStudentInvoiceData({'headers':headerState, 'products':finalProductList})

        //stopping the circular progress
        setIsLoading(false);
        
        //ping invoice details on telegram
        const message = `Student Invoice Created Successfully
School Name : ${headerState['schoolName']} 
Invoice No. :${headerState['bill_no']}
Student Name :${headerState['student_name']}
Class : ${headerState['class']}
Roll No. : ${headerState['roll_no']} 
House : ${headerState['house_name']} 
Invoice Date : ${headerState['date_of_purchase']}
Total Items : ${headerState['total_items']}
Total Amount : ${headerState['total_price']}
Generate By : ${props.schoolData['userEmail']}
All Details Saved in Database.`
        telegram(message)

        // Navigating to view Invoice Page
        navigate('/home/invoice/student/createInvoice/view')
        }
        else if (newData['response'] === 500){
            addToast({title: 'Invalid Input', description: 'One or more products/sizes not found in inventory', status: 'error'})
            setIsLoading(false);
            setIsVisible(true);
            setAlertType(1);
        }else if (newData['response'] === 300){
            addToast({title: 'Database Error', description: 'Duplicate Invoice', status: 'error'})
            setIsLoading(false)
            setIsVisible(true);
            setAlertType(2);
        }

    }   
        persistData();
        } 
            
    return(
        <ChakraProvider>
            <Center >
            
            <FormControl isDisabled={disabled}>
                <VStack spacing={5}>
                <Box p={5} borderWidth={1} borderRadius={8} boxShadow="lg">
                <VStack>
                <Heading size='lg' as='h3' p={5} pt={0}><Icon as={TbFileInvoice} boxSize={6}/>&nbsp; Create New Student Invoice</Heading>

                    <FormControl isRequired>
                        <VStack spacing={5}>
                        <HStack>
                        <HStack>
                            <FormLabel fontSize='xs'>Name</FormLabel>
                            <Input isDisabled={disabled}  type='text'  name='student_name' onChange={(e)=> setHeaderState({ ...headerState,student_name:e.target.value })}/>
                        </HStack>

                        <HStack>
                            <FormLabel fontSize='xs' >Class</FormLabel>
                            <NumberInput name="class" isDisabled={disabled}>
                                <NumberInputField   onChange={(e)=> setHeaderState({ ...headerState,class:e.target.value })}/>
                            </NumberInput>
                        </HStack>

                        <HStack>
                            <FormLabel fontSize='xs' >Roll No.</FormLabel>
                            <NumberInput name="roll_no" isDisabled={disabled}>
                                <NumberInputField   onChange={(e)=> setHeaderState({ ...headerState,roll_no:e.target.value })}/>
                            </NumberInput>
                        </HStack>
            
                        </HStack> 

                        <HStack>
                        <HStack>
                            <FormLabel w={'10em'} fontSize='xs'>Invoice Date</FormLabel>
                            <Input isDisabled={disabled} type='date' name='date_of_purchase' onChange={(e)=> setHeaderState({ ...headerState,date_of_purchase:e.target.value })}/>    
                        </HStack> 
                        
                        <HStack>
                            <FormLabel fontSize='xs'>House</FormLabel>
                            <Select isDisabled={disabled} placeholder='Select House' size='md' name="house_name" onChange={(e)=> setHeaderState({ ...headerState,house_name:e.target.value })}>
                                {houseList.map(MakeItem)}                
                            </Select>
                        </HStack>
                        
                        </HStack>
                        </VStack>  
                    </FormControl>
                    </VStack>                    
                </Box>
        
        <Box p={5} borderWidth={1} borderRadius={8} boxShadow="lg">
        <VStack spacing={5}>
        <Skeleton isLoaded={!loading}>    
        <Table >
        <Thead>
          <Tr>
            <Th>Sr.</Th>
            <Th>Item Name</Th>
            <Th>Price</Th>
            <Th>Sizes Available</Th>
            <Th>Quantity</Th>
            <Th>Total Price</Th>
          </Tr>
        </Thead>
        <Tbody>
        {
        productList.map((value,index) => {  
            return(
                <Tr key={index}>
                    <Th>{index+1}</Th>
                    <Th>{value['product_name']}</Th>
                    <Th>&#8377;&nbsp;{parseInt(value['product_price']).toLocaleString('en-IN')}</Th>
                    <Td>
                        <Select isDisabled={(value['sizes_present']).length === 0 || disabled} placeholder='Select Size' size='md' name="size" onChange={(e)=> onChangeInput(e, index)}>
                            {value['sizes_present'].map(MakeItem)}                
                        </Select>
                    </Td>
                    <Td>
                        <NumberInput isDisabled={disabled}>
                            <NumberInputField name= "item_quantity"  onChange={(e)=> onChangeInput(e, index)}/>
                        </NumberInput>
                    </Td>
                    
                    {disabled? <Th>&#8377;&nbsp;{value['total_price']}</Th>: <Th>-</Th>}
                </Tr>
            )
        })
        }
        </Tbody>
      </Table>  
      </Skeleton> 
      {!disabled ? (  <Center>
                    <Button colorScheme='telegram' variant='solid' onClick={(e)=>handleSubmit(e)} rightIcon={<IoArrowForward/>}>Confirm</Button>
                    </Center>
                    ) : (
                        <VStack spacing={5}>
                            <HStack spacing={10}>
                                <HStack>
                                    <Heading size={'sm'}>Total Items</Heading>
                                    <Text>{(headerState['total_items']).toLocaleString('en-IN')}</Text>
                                </HStack>
                                <HStack>
                                    <Heading size={'sm'}>Total Amount</Heading>
                                    <Text>&#8377;&nbsp;{(headerState['total_price']).toLocaleString('en-IN')}</Text>
                                </HStack>
                                <HStack>
                                    <Heading size={'sm'}>Invoice#</Heading>
                                    <Text>{headerState['bill_no']}</Text>
                                </HStack>
                            </HStack>
                            {headerState.total_items === 0 ? <InvoiceInputAlert alertType={3}/> : null}
                            <HStack>
                                <Button colorScheme='pink' variant='solid' onClick={goBack} isDisabled={isLoading} leftIcon={<IoArrowBack/>}>Go Back</Button>
                                <Button colorScheme='whatsapp' variant='solid' onClick={createInvoice} leftIcon={<TbFileInvoice/>} isDisabled={headerState.total_items === 0 ? true : false || isLoading}>{isLoading ? (<CircularProgress isIndeterminate size="24px" color="teal" />) :
            ('Create Invoice')}</Button>
                            </HStack>
                            {isVisible ? (<InvoiceInputAlert alertType={alertType}/>
                                        ) : (null)}
                        </VStack>
                    )}
      </VStack>
    </Box>
    </VStack> 
    </FormControl>
    </Center>    
    </ChakraProvider>
    )

}

export default StudentInvoiceInputForm;