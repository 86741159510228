import { useToast } from "@chakra-ui/react";
export const CustomToast = () => {
    
    const toast =useToast();
    const addToast = (message) => { 
        toast({
          title: message.title,
          description: message.description,      
          status: message.status,
          duration: 2000,
          isClosable: true,
          position:'top-right'
      })
    }
    return {addToast}
}