import { Drawer,DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, useDisclosure, Text, CloseButton, Flex, Spacer, Link } from "@chakra-ui/react";
export default function Cookie(){
    const { isOpen, onClose } = useDisclosure({defaultIsOpen:window.localStorage.getItem('visited') === 'true' ? false : true})

    return(
        <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
            <DrawerHeader bg={'teal'}>
                <Flex>
                Cookie Consent
                <Spacer/>
                <CloseButton size={'sm'} onClick={onClose}/>
                </Flex>
            
            </DrawerHeader>
          <DrawerBody >
            <Flex>
                <Text>We respect your privacy and use cookies to enhance your experience. By using our website and services, you Agree to our use of cookies in compliance with the <Link href='https://www.meity.gov.in/writereaddata/files/Digital%20Personal%20Data%20Protection%20Act%202023.pdf' isExternal><Text as={'u'}>DPDPA</Text></Link> 2023 India.</Text>
                
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    )
}