import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '##D5DBDB'
const styles = StyleSheet.create({
    container1: {
        flexDirection: 'row',
        borderWidth: 1,
        borderStyle: 'dashed',
        alignItems: 'center',
        textAlign: 'center',
        flexGrow: 1,
        height: 24,
    },
    bill_date: {
        width: '30%',
        fontStyle: 'Bold',
        margin : '4px',
        borderRightWidth: 1,
        borderColor:borderColor,
    },
    duration:{
        width: '40%',
        fontStyle: 'Bold',
        margin : '4px',
        borderRightWidth: 1,
        borderColor:borderColor,
        textAlign: 'center'
    },
    bill_no: {
        width: '30%',
        margin : '4px',
        borderRightWidth: 1,
        borderColor:borderColor,
    },
    container2: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderStyle: 'dashed',
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        height: 24,
    },
  });

  function RaashanPDFHeader (props) {
    const header = props.header
    let date = new Date(header['inv_date'])
    const billDate = `${(date.getDate() < 10 ? '0':'')+date.getDate()}-${((date.getMonth() + 1) < 10 ? '0':'')+(date.getMonth() + 1)}-${date.getFullYear()}`;
    date = new Date(header['start_date'])
    const startDate = `${(date.getDate() < 10 ? '0':'')+date.getDate()}-${((date.getMonth() + 1) < 10 ? '0':'')+(date.getMonth() + 1)}-${date.getFullYear()}`;
    date = new Date(header['end_date'])
    const endDate = `${(date.getDate() < 10 ? '0':'')+date.getDate()}-${((date.getMonth() + 1) < 10 ? '0':'')+(date.getMonth() + 1)}-${date.getFullYear()}`;

    return(
    <>
    <View style={styles.container1}>
        <Text style={styles.bill_date}>Bill Date</Text>
        <Text style={styles.duration}>Bill Duration</Text>
        <Text style={styles.bill_no}>Bill No.</Text>
    </View>
    <View style={styles.container2}>
        <Text style={styles.bill_date}>{billDate}</Text>
        <Text style={styles.duration}>{startDate} to {endDate}</Text>
        <Text style={styles.bill_no}>{header['invoice_no']}</Text>
    </View>
    </>
    )
  };
  
  export default RaashanPDFHeader