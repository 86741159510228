import {Center, useToast } from '@chakra-ui/react';
import InvoiceTableHeader from './StudentInvoiceSearchTableHeader';
import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import InvoiceSearchHeader from './StudentInvoiceSearchHeader';
import SearchInvoiceTableProducts from './StudentInvoiceSearchTableProducts';
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#E4E4E4',
    alignItems: 'center',
    textAlign: 'center',
    fontFamily:'Courier'

  },
  section: {
    margin: 'auto',
    fontSize: 12,
  },
  footer: {
    margin: 'auto',
	  fontSize: 10
  },
  totalsection:{
    borderWidth: 1,
    borderStyle: 'dashed',
  }
  
});


// Create Document Component
function  MyDocument (data) {
    const toast =useToast(); //declaring toast
    const notify = (newRes) => toast({
        title: newRes.title,
        description: newRes.description,      
        status: newRes.status,
        duration: 3000,
        isClosable: true,
        position:'top-right'
    })
    // Displaying the toast
    notify({title: 'Success', description: 'Invoice Created Successfully', status: 'success'})

    let x =data.header 
    return(
    <Document title={x['bill_no']}>
        <Page size="A4" style={styles.page}>
        <View style={styles.section}>
            <Text style={styles.section}>Student Invoice</Text>
            <Text style={styles.section}>Prika Way Private Limited</Text>
        <Text style={styles.section}>Unit: Tuck Shop {x['schoolName']}</Text>
        <Text style={styles.section}>Customer Care: care@prikaway.com, +91-8002003353  GSTIN: 10AAKCP3572D1ZR</Text>
        <Text>&nbsp;</Text>
            <InvoiceSearchHeader header={data.header}/>
        <Text>&nbsp;&nbsp;</Text>
        <InvoiceTableHeader/>
        <SearchInvoiceTableProducts products={data.products}/>
        <Text>&nbsp;</Text>
        <View style={styles.totalsection}>
        <Text>Total Number of Items: {x['total_items']}</Text>
        <Text>Total Amount Payable: {Number(x['total_price']).toLocaleString('en-IN')}</Text>
        <Text>Total Amount Payable(In Words): {x['word_amount']}</Text>
        </View>
        <Text>&nbsp;&nbsp;</Text>
        <Text style={styles.footer}>All prices are in INR and inclusive of GST</Text>
        <Text>&nbsp;&nbsp;</Text>
        <Text>&nbsp;&nbsp;</Text>
        <Text>&nbsp;&nbsp;</Text>
        <Text>&nbsp;&nbsp;</Text>
        <Text>&nbsp;&nbsp;</Text>
        <Text>&nbsp;&nbsp;</Text>
        <Text>&nbsp;&nbsp;</Text>
        <Text style={styles.footer}>Cadet Signature&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;House Master Signature&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vendor Signature</Text>
        </View>
        </Page>
    </Document>
    );
    }

export default function StudentInvoicePDF(props) {
    
    const ter = props.createStudentInvoiceData;
    var prodArray=Object.values(ter['products']) //converting the obejct to array
    return(   
     
    <Center>
    <PDFViewer height={'900em'} width={'1000em'}>
        <MyDocument header={ter['headers']} products={prodArray}/>
      </PDFViewer>
    
    </Center>
    )
}