import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '##D5DBDB'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    sr:{
        width:'5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    description: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        marginLeft:'2px'
    },
    qty: {
        width: '25%',
        borderRightColor: borderColor,
        borderRightWidth: 1,

    },
    rate: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,

    },
    amount: {
        width: '20%',

    },
  });

  
function AllHouseCoverPDFTableRows (props) {
    var counter=0
    const rows = props.houseData.map( item => 
        <View style={styles.container} key={item['House Name']}>
            <Text style={styles.sr}>{counter+=1}</Text>
            <Text style={styles.description}>{item['House Name']}</Text>
            <Text style={styles.qty}>{item['Count of Students']}</Text>
            <Text style={styles.rate}>{Number(item['Item Quantity']).toLocaleString('en-IN')}</Text>
            <Text style={styles.amount}>{Number(item['Total Price']).toLocaleString('en-IN')}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  export default AllHouseCoverPDFTableRows;