import { Menu, MenuButton, MenuList, Avatar, AvatarBadge, HStack, Text, VStack, MenuItem, Heading} from "@chakra-ui/react";
import { IoIosLogOut, IoMdSettings  } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import telegram from "../MainSite/Telegram";
import ThemeToggler from "../MainSite/ThemeToggler";
import { CustomToast } from "../MainSite/Toast";
import { BsInfoCircleFill } from "react-icons/bs";

export default function UserMenu(){
    
    const {addToast} = CustomToast();
    const navigate = useNavigate();
    const navigateToMain = () => {
        addToast({title: 'Completed', description: 'Log Out Successful', status: 'warning'});
        telegram(`User ${window.localStorage.getItem('user_email')} logged out.`)
        navigate('/');
        localStorage.clear();
        localStorage.setItem('visited', true);
      };
      
    return(
        <Menu>
            <MenuButton>
            <Avatar name={window.localStorage.getItem('user_name')} src={window.localStorage.getItem('pic_url')}>
            <AvatarBadge boxSize='1.25em' bg='green.500' />
            </Avatar>                                    
            </MenuButton>
            <MenuList>
            <MenuItem>
            <HStack spacing={5}>
                <VStack alignItems={'left'}>
                    <Heading as={'h6'} size={'sm'}>{window.localStorage.getItem('user_name')}</Heading>
                    <Text>{window.localStorage.getItem('user_email')}</Text>
                </VStack>
                <ThemeToggler/>
            </HStack>
            </MenuItem>
            <MenuItem icon={<IoMdSettings/>} onClick={()=> navigate('/home/settings')}>
                Settings
            </MenuItem>
            <MenuItem icon={<BsInfoCircleFill/>} onClick={()=> navigate('/home/about')}>
                About
            </MenuItem>
            <MenuItem _hover={{bg:'red', color:'white'}} variant='solid' onClick={navigateToMain} as={'b'} icon={<IoIosLogOut/>}>
                Log Out
            </MenuItem>
            </MenuList>
        </Menu>
                                
    )
}