import React from 'react';
import { useColorMode, Box, IconButton, Tooltip } from '@chakra-ui/react';
import { BsSun, BsMoon } from "react-icons/bs";


export default function ThemeToggler() {
    const { colorMode, toggleColorMode } = useColorMode();
    return (
      <Box textAlign="right" py={4} mr={2}>
        <Tooltip label="Toggle Theme" aria-label='Toggle-Theme'>
          <IconButton
            icon={colorMode === 'light' ? <BsMoon/> : <BsSun/>}
            onClick={toggleColorMode}
            variant="ghost"
            aria-label='Toggle-Theme'
            
            color={'gray'}
          />
        </Tooltip>
      </Box>
    );
  }