import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '##D5DBDB'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    sr:{
        width:'5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    description: {
        width: '55%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'left',
        marginLeft:'2px'
    },
    size:{
        width:'10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,

    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,

    },
    rate: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,

    },
    amount: {
        width: '10%',

    },
  });

  
function PrincipalInvoiceTableProducts (props) {
    var counter=0
    const rows = props.products.map( item => 
        <View style={styles.container} key={item['Product Name']}>
            <Text style={styles.sr}>{counter+=1}</Text>
            <Text style={styles.description}>{item['Product Name']}</Text>
            <Text style={styles.qty}>{item['Item Quantity']}</Text>
            <Text style={styles.rate}>{Number(item['Unit Price']).toLocaleString('en-IN')}</Text>
            <Text style={styles.amount}>{Number(item['Total Price']).toLocaleString('en-IN')}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  export default PrincipalInvoiceTableProducts;