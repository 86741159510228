export const SlideData = [
    {
      image:
        "https://i.postimg.cc/zvzcwy48/Whats-App-Image-2024-03-01-at-5-14-55-PM.jpg"
    },
    {
      image:
        "https://i.postimg.cc/NM7nr6Jd/Whats-App-Image-2024-03-01-at-5-14-55-PM-1.jpg"
    },
    {
      image:
        "https://i.postimg.cc/C5c6cFVD/Whats-App-Image-2024-03-01-at-5-14-56-PM-1.jpg"
    },
    {
      image:
        "https://i.postimg.cc/9QRNB5gR/Whats-App-Image-2024-03-01-at-5-14-57-PM-1.jpg"
    },
    {
        image:
          "https://i.postimg.cc/N0RJW8cQ/Whats-App-Image-2024-03-01-at-5-14-59-PM.jpg"
    },
    {
        image:
            "https://i.postimg.cc/SNtv8Ndb/Whats-App-Image-2024-03-01-at-5-14-59-PM-1.jpg"
    }

  ];