import { Flex, Square, Center, Box, Heading,  VStack, Icon } from '@chakra-ui/react'
import { IoMdSettings  } from "react-icons/io";
import ThemeToggler from './ThemeToggler'
export default function Settings(){
    return(
        <Center mb={'18em'}>
            <VStack spacing={10} p={5} borderWidth={1} borderRadius={8} boxShadow="lg">
                <Heading as='h3' size='lg'><Icon as={IoMdSettings} boxSize={6}/>&nbsp; Settings</Heading>
                <Flex>
                    <Square  w='10em'>
                        <Heading as={'h3'} size={'sm'}>Change Theme</Heading>
                    </Square>
                    <Box flex='1' p={20}>
                        <ThemeToggler/>    
                    </Box>    
                </Flex>
            </VStack>
        </Center>
        
    )
}