import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '##D5DBDB'
export const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderWidth: 1,
        height: 24,
        fontStyle: 'bold',
        flexGrow: 1,
        alignItems:'center'
    },
    sr:{
        width:'5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    description: {
        width: '45%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate:{
        width:'10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    gst: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    price: {
        width: '20%',
    },
    productRows: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    }
  });

  const RaashanPDFTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.sr}>Sr.</Text>
        <Text style={styles.description}>Item Description</Text>
        <Text style={styles.rate}>Rate</Text>
        <Text style={styles.gst}>GST</Text>
        <Text style={styles.qty}>Qty.</Text>
        <Text style={styles.price}>Price</Text>
    </View>
  );
  
  export default RaashanPDFTableHeader