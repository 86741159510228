import { Center, HStack, Heading, Icon, VStack, Image } from "@chakra-ui/react";
import { FaReact, FaPython, FaGithub } from "react-icons/fa";
import { BiLogoPostgresql } from "react-icons/bi";
import { IoLogoVercel } from "react-icons/io5";
import { SiChakraui } from "react-icons/si";

export default function About(){
    return(
        <Center>
            <VStack spacing={5}>            
                <Heading>Version : 24.6.31 Stable Production Build</Heading>
                <Heading>Powered By</Heading>    
                
                <HStack spacing={10}> 
                    <VStack>
                        <Icon boxSize={40} as={FaReact}></Icon>
                        <Heading>React</Heading>
                    </VStack> 
                    <VStack>
                        <Icon boxSize={40} as={SiChakraui}></Icon>
                        <Heading>Chakra UI</Heading>
                    </VStack>  
                    <VStack>
                        <Icon boxSize={40} as={FaPython}></Icon>
                        <Heading>Python</Heading>
                    </VStack> 
                    <VStack>
                        <Icon boxSize={40} as={BiLogoPostgresql}></Icon>
                        <Heading>PostgreSQL</Heading>
                    </VStack>  
                    <VStack>
                        <Icon boxSize={40} as={FaGithub}></Icon>
                        <Heading>GitHub</Heading>
                    </VStack>
                    <VStack>
                        <Icon boxSize={40} as={IoLogoVercel}></Icon>
                        <Heading>Vercel</Heading>
                    </VStack>
                </HStack>
                <Image src='https://i.postimg.cc/mkzpMM11/Make-In-India.png' objectFit={'cover'} boxSize={80}></Image>
            </VStack>
        </Center>
    )
}