import {Stat,
    StatLabel,
    StatHelpText,
    StatNumber,
    Skeleton,
    StatGroup,
    } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
export default function StatData(){
    
    const [statData, setStatData] = useState({
        count:'',
        bill_no:'',
        date_of_purchase:''

    });
    const [loading, setLoading] = useState(true);
        useEffect(() => {
         
        const fetchData = async () => {
          const response = await fetch(process.env.REACT_APP_API+`/get_stat_data?school_id=${window.localStorage.getItem('school_id')}`);
          var newData = await response.json();
          setStatData({count:newData['count'], bill_no:newData['bill_no'], date_of_purchase:newData['date_of_purchase']});
          setLoading(false);
        };
        fetchData();
      },[]);
    //getting today's date 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yy = today.getFullYear();
    today = dd + '-' + mm + '-' + yy;
    //getting first day of the year
    var d = new Date(new Date().getFullYear(), 0, 1);
    var ddd = String(d.getDate()).padStart(2, '0')
    var mmm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyy = d.getFullYear();
    d= ddd + '-' + mmm +  '-' + yyy;
    var message = `From ${d} to ${today}`
    return(

        <Skeleton isLoaded={!loading}>
            <StatGroup>
                <Stat color={'gray'} w={'15em'} >
                    <StatLabel fontSize={'xs'}>Count of Invoices Generated</StatLabel>
                    <StatNumber fontSize={'xs'}>{statData['count']}</StatNumber>
                    <StatHelpText fontSize={'xs'}>{message}</StatHelpText>
                </Stat>

                <Stat color={'gray'}>
                    <StatLabel fontSize={'xs'}>Last Student Invoice Details</StatLabel>
                    <StatNumber fontSize={'xs'}>{statData['date_of_purchase']}</StatNumber>
                    <StatHelpText fontSize={'xs'}>{statData['bill_no']}</StatHelpText>
                </Stat>
            </StatGroup>
        </Skeleton>

    )
}