import { Center, Heading, VStack, Input, Text, HStack, Box, RadioGroup, Radio, Button, Table, Td, Th, Tr, Tbody, Thead, Icon, FormLabel, Select } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { MdCalculate, MdOutlinePivotTableChart } from "react-icons/md";
import { RxReset } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

export default function CoverPageLanding(props){
    var [headerData,setHeaderData] =useState({});
    var [houseData,setHouseData] =useState({});
    const [houseList, setHouseList] = useState([]);

    const navigate = useNavigate();
    const [searchFormState, setSearchFormState] =useState({
        startDate: "",
        endDate: "",
        tcType: "",
        house:"",
    })
    const [foundType,setFoundType] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
          const response = await fetch(process.env.REACT_APP_API+`/db_house_search?school_id=${window.localStorage.getItem('school_id')}`);
          const newData = await response.json();
          setHouseList(['All', ...newData]);
        }; 
        fetchData();
    },[]);

    const MakeItem = function(X) {
        return <option>{X}</option>;
    };

    const handleSubmit = async event => {
        event.preventDefault();  
            if (searchFormState.house === 'All'){
            const a = await (await fetch(process.env.REACT_APP_API+`/db_all_house_cover_page?start_date=${searchFormState.startDate}&end_date=${searchFormState.endDate}&school_id=${window.localStorage.getItem('school_id')}&tc_leave=${searchFormState.tcType}`)).json();
            if (a.found === true){
                setFoundType(1);
                setHeaderData(a.header);
                setHouseData(JSON.parse(a.data));
                props.setAllHouseCoverData(a)
            }
        }else{
            const a = await (await fetch(process.env.REACT_APP_API+`/db_individual_house_cover_page?start_date=${searchFormState.startDate}&end_date=${searchFormState.endDate}&house=${searchFormState.house}&school_id=${window.localStorage.getItem('school_id')}&tc_leave=${searchFormState.tcType}`)).json();
            if (a.found === true){
                setFoundType(2);
                setHeaderData(a.header);
                setHouseData(JSON.parse(a.data));
                props.setAllHouseCoverData(a)
        }           
        }
    }
        const handleClear= () => {
            setSearchFormState({
                startDate: "",
                endDate: "",
                tcType: "",
                house:"",
            })
            setFoundType(0);
        }
        //creating the PDF
        const createPDF = () =>{
            if (foundType === 1) {
                navigate('/home/coverPage/allHouseCover/view')
            }else if (foundType === 2) {
                navigate('/home/coverPage/HouseCover/view')
            }
                
        }


    return(
        <Center>
            <VStack>
                <Box p={5} borderWidth={1} borderRadius={8} boxShadow="lg" mb={foundType===0?'20em':'2em'}>
                    <Center>
                        <VStack>
                        <Heading as={'h3'} size={'lg'}><Icon as={MdOutlinePivotTableChart} boxSize={6}/>&nbsp; Generate House Cover Pages </Heading>

                    <Heading as='h5' size={'md'} mt={5}>Enter Search Criteria</Heading>
                    <HStack spacing={10} m={5}>
                        <HStack>
                            <FormLabel w={'120px'} fontSize={'sm'}>Start Date</FormLabel>
                                <Input isRequired type="date"placeholder="Enter Date" onChange={(e)=> setSearchFormState({ ...searchFormState, startDate:e.target.value })} value={searchFormState.startDate} name='startDate'/>
                        </HStack>
                        <HStack>
                            <FormLabel w={'100px'} fontSize={'sm'}>End Date</FormLabel>
                            <Input isRequired type="date" placeholder="Enter Date" onChange={(e)=> setSearchFormState({ ...searchFormState, endDate:e.target.value })} value={searchFormState.endDate} name='endDate'/> 
                        </HStack>
                    </HStack>
                    <HStack spacing={10}>
                    <RadioGroup mb={2} value={searchFormState.tcType}>
                        <HStack>
                            <FormLabel fontSize={'sm'}>TC/Leave Status</FormLabel>
                            <Radio name='invType' value='False' onChange={(e)=> setSearchFormState({ ...searchFormState, tcType:e.target.value })} > No</Radio>
                            <Radio name='invType' value='True' onChange={(e)=> setSearchFormState({ ...searchFormState, tcType:e.target.value })} >Yes</Radio>
                        </HStack>
                    </RadioGroup>
                    
                    <HStack>
                            <FormLabel fontSize='sm'>House</FormLabel>
                            <Select placeholder='Select House' size='md' name="house" value={searchFormState.house} onChange={(e)=> setSearchFormState({ ...searchFormState,house:e.target.value })}>
                                {houseList.map(MakeItem)}                
                            </Select>
                    </HStack>
                    </HStack>
                    <HStack p={5}>
                    <Button variant={'solid'} colorScheme="telegram" onClick={handleSubmit} isDisabled={foundType===0?false:true} leftIcon={<MdCalculate/>}>Calculate</Button>
                    <Button variant={'solid'} colorScheme="pink" onClick={handleClear} leftIcon={<RxReset/>}>Reset</Button>
                    </HStack>
                    </VStack>
                    </Center> 
                </Box> 
                {(foundType === 1) ?( //print for all house cover page
            <Box p={5} borderWidth={1} borderRadius={8} boxShadow="lg">    
            <Table>
                <Thead>
                    <Tr>
                        <Th>Grand Total of Items</Th>
                        <Th>Grand Total of Amount</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>{parseInt(headerData['item_quantity']).toLocaleString('en-IN')}</Td>
                        <Td>&#8377;&nbsp;{parseInt(headerData['total_price']).toLocaleString('en-IN')}</Td>
                    </Tr>
                </Tbody>
            </Table>
            <br/>
            <Table >
            <Thead>
            <Tr>
                <Th>Sr.</Th>
                <Th>House</Th>
                <Th>Count of Students</Th>
                <Th>Total No. of Items</Th>
                <Th>Total Amount</Th>
            </Tr>
            </Thead>
            <Tbody>
            {Object.keys(houseData).map((key,i)=> (<Tr key={i}>
                <Td>{i+1}</Td>
                <Td>{houseData[key]['House Name']}</Td>
                <Td>{(houseData[key]['Count of Students']).toLocaleString('en-IN')}</Td>
                <Td>{(houseData[key]['Item Quantity']).toLocaleString('en-IN')}</Td>
                <Td>&#8377;&nbsp;{(houseData[key]['Total Price']).toLocaleString('en-IN')}</Td>
            </Tr>))}
            
            </Tbody>
        </Table>
        <Center m={5}>
            <VStack spacing={5}>
                <Text><b>Total Amount in Words:</b>&nbsp;{headerData['word_amount']}</Text>
                <Button variant={'solid'} onClick={createPDF} colorScheme="whatsapp" leftIcon={<MdOutlinePivotTableChart/>}>Confirm Details and Create PDF</Button>
            </VStack>
        </Center>
        </Box>
        ):( // print for individual house cover page
        (foundType === 2)?(
            <Box p={5} borderWidth={1} borderRadius={8} boxShadow="lg">    
            <Table>
                <Thead>
                    <Tr>
                        <Th>Grand Total of Items</Th>
                        <Th>Grand Total of Amount</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>{parseInt(headerData['item_quantity']).toLocaleString('en-IN')}</Td>
                        <Td>&#8377;&nbsp;{parseInt(headerData['total_price']).toLocaleString('en-IN')}</Td>
                    </Tr>
                </Tbody>
            </Table>
            <br/>
            <Table >
            <Thead>
            <Tr>
                <Th>Sr.</Th>
                <Th>Roll No.</Th>
                <Th>Student Name</Th>
                <Th>Class</Th>
                <Th>Item Quantity</Th>
                <Th>Total Amount</Th>
            </Tr>
            </Thead>
            <Tbody>
            {Object.keys(houseData).map((key,i)=> (<Tr key={i}>
                <Td>{i+1}</Td>
                <Td>{houseData[key]['Roll No.']}</Td>
                <Td>{houseData[key]['Student Name']}</Td>
                <Td>{houseData[key]['Class']}</Td>
                <Td>{(houseData[key]['Item Quantity']).toLocaleString('en-IN')}</Td>
                <Td>&#8377;&nbsp;{(houseData[key]['Total Price']).toLocaleString('en-IN')}</Td>
            </Tr>))}
            
            </Tbody>
        </Table>
        <Center m={5}>
            <VStack spacing={5}>
                <Text><b>Total Amount in Words:</b>&nbsp;{headerData['word_amount']}</Text>
                <Button variant={'solid'} onClick={createPDF} colorScheme="whatsapp" leftIcon={<MdOutlinePivotTableChart/>}>Confirm Details and Create PDF</Button>
            </VStack>
        </Center>
        </Box>
        ):(null)
        )} 

            </VStack>
        </Center>
        
    )
}